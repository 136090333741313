import React from "react";
import { MainHeader, MainFooter } from '../components/Layout'
import './css/PrivacyPolicy.css';

function PrivacyPolicy() {
  return (

    <React.Fragment>
        <MainHeader/>
        <div className='privacy-policy'>

            <div className='privacy-policy-header'>
                <h1>Privacy Policy</h1>
                <p>Updated at 2024-03-20</p>
            </div>

            <p>
                StreamHost (“we,” “our,” or “us”) is committed to protecting your
                privacy. This Privacy Policy explains how your personal information is
                collected, used, and disclosed by StreamHost.
            </p>
            <p>
                This Privacy Policy applies to our website, and its associated
                subdomains (collectively, our “Service”) alongside our application,
                StreamHost. By accessing or using our Service, you signify that you
                have read, understood, and agree to our collection, storage, use, and
                disclosure of your personal information as described in this Privacy
                Policy and our Terms of Service. This Privacy Policy was created with{" "}
                <a href="https://termify.io" target="_blank">
                Termify
                </a>
                .
            </p>

            <h2>Definitions and key terms</h2>
            <p>
                To help explain things as clearly as possible in this Privacy Policy,
                every time any of these terms are referenced, are strictly defined as:
                <ul>
                    <li>
                        Cookie: small amount of data generated by a website and saved by
                        your web browser. It is used to identify your browser, provide
                        analytics, remember information about you such as your language
                        preference or login information.
                    </li>
                    <li>
                        Company: when this policy mentions “Company,” “we,” “us,” or “our,”
                        it refers to STREAMHOST PLT, A-16-12 MERCU SUMMER SUITES 8 JALAN
                        CENDANA KAMPUNG BARU KUALA LUMPUR 50250 KUALA LUMPUR W.P KUALA
                        LUMPUR that is responsible for your information under this Privacy
                        Policy.
                    </li>
                    <li>
                        Country: where StreamHost or the owners/founders of StreamHost are
                        based, in this case is Malaysia
                    </li>
                    <li>
                        Customer: refers to the company, organization, or person that signs
                        up to use the StreamHost Service to manage the relationships with
                        your consumers or service users.
                    </li>
                    <li>
                        Device: any internet connected device such as a phone, tablet,
                        computer or any other device that can be used to visit StreamHost
                        and use the services.
                    </li>
                    <li>
                        IP address: Every device connected to the Internet is assigned a
                        number known as an Internet protocol (IP) address. These numbers
                        are usually assigned in geographic blocks. An IP address can often
                        be used to identify the location from which a device is connecting
                        to the Internet.
                    </li>
                    <li>
                        Personnel: refers to those individuals who are employed by
                        StreamHost or are under contract to perform a service on behalf of
                        one of the parties.
                    </li>
                    <li>
                        Personal Data: any information that directly, indirectly, or in
                        connection with other information — including a personal
                        identification number — allows for the identification or
                        identifiability of a natural person.
                    </li>
                    <li>
                        Service: refers to the service provided by StreamHost as described
                        in the relative terms (if available) and on this platform.
                    </li>
                    <li>
                        Third-party service: refers to advertisers, contest sponsors,
                        promotional and marketing partners, and others who provide our
                        content or whose products or services we think may interest you.
                    </li>
                    <li>
                        Website: StreamHost's site, which can be accessed via this URL:{" "}
                        <a href="https://streamhost.app/">https://streamhost.app/</a>
                    </li>
                    <li>
                        You: a person or entity that is registered with StreamHost to use
                        the Services.
                    </li>
                </ul>
            </p>
            <h2>What Information Do We Collect?</h2>
            <p>
                We collect information from you when you visit our website, register on
                our site, place an order, subscribe to our newsletter, respond to a
                survey or fill out a form.
            </p>
            <ul>
                <li>Name / Username</li>
                <li>Phone Numbers</li>
                <li>Email Addresses</li>
                <li>Job Titles</li>
                <li>Billing Addresses</li>
            </ul>
            <p>
                We also collect information from mobile devices for a better user
                experience, although these features are completely optional:
            </p>
            <ul>
                <li>
                    Phonebook (Contacts list): Your contacts list allows the website to
                    be much more easy to use by the user, since accessing your contacts
                    from the app makes you save tons of time.
                </li>
            </ul>

            <h2>How Do We Use The Information We Collect?</h2>
            <p>
                Any of the information we collect from you may be used in one of the
                following ways:
                <ul>
                <li>
                    To personalize your experience (your information helps us to better
                    respond to your individual needs)
                </li>
                <li>
                    To improve our website (we continually strive to improve our website
                    offerings based on the information and feedback we receive from
                    you)
                </li>
                <li>
                    To improve customer service (your information helps us to more
                    effectively respond to your customer service requests and support
                    needs)
                </li>
                <li>To process transactions</li>
                <li>
                    To administer a contest, promotion, survey, or other site feature
                </li>
                <li>To send periodic emails</li>
                </ul>
            </p>

            <h2>When does StreamHost use end-user information from third parties?</h2>
            <p>
                StreamHost will collect End User Data necessary to provide the
                StreamHost services to our customers.
            </p>
            <p>
                End users may voluntarily provide us with information they have made
                available on social media websites. If you provide us with any such
                information, we may collect publicly available information from the
                social media websites you have indicated. You can control how much of
                your information social media websites make public by visiting these
                websites and changing your privacy settings.
            </p>

            <h2>When does StreamHost use customer information from third parties?</h2>
            <p>
                We receive some information from the third parties when you contact us.
                For example, when you submit your email address to us to show interest
                in becoming a StreamHost customer, we receive information from a third
                party that provides automated fraud detection services to StreamHost.
                We also occasionally collect information that is made publicly available
                on social media websites. You can control how much of your information
                social media websites<h2>Does StreamHost share the information it collects with third parties?</h2>
            </p>
            <p>
            StreamHost does not sell, trade, or rent your personal information to third parties. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
            </p>
            <h2>How long do we retain your information?</h2>
            <p>
            We will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
            </p>
            <h2>How do we protect your information?</h2>
            <p>
            We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.
            </p>
            <p>
            We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.
            </p>
            <p>
            After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.
            </p>
            <h2>Do we use cookies?</h2>
            <p>
            Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.
            </p>
            <p>
            We use cookies to help us remember and process the items in your shopping cart, understand and save your preferences for future visits, keep track of advertisements and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
            </p>
            <h2>Do we disclose any information to outside parties?</h2>
            <p>
            We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
            </p>
            <h2>Third party links</h2>
            <p>
            Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
            </p>
            <h2>California Online Privacy Protection Act Compliance</h2>
            <p>
            Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We, therefore, will not distribute your personal information to outside parties without your consent.
            </p>
            <p>
            As part of the California Online Privacy Protection Act, all users of our site may make any changes to their information at any time by logging into their control panel and going to the 'Edit Profile' page.
            </p>
            <h2>Childrens Online Privacy Protection Act Compliance</h2>
            <p>
            We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products, and services are all directed to people who are at least 13 years old or older.
            </p>
            <h2>Online Privacy Policy Only</h2>
            <p>
            This online privacy policy applies only to information collected through our website and not to information collected offline.
            </p>
            <h2>Your Consent</h2>
            <p>
            By using our site, you consent to our privacy policy.
            </p>
            <h2>Changes to our Privacy Policy</h2>
            <p>
            If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.
            </p>
            <p>
            This policy was last modified on 2024-03-20
            </p>
            <h2>Contacting Us</h2>
            <p>
            If there are any questions regarding this privacy policy you may contact us using the information below.
            </p>
            <p>
            <a href="https://streamhost.app/">https://streamhost.app/</a><br />
            A-16-12 MERCU SUMMER SUITES 8 JALAN CENDANA KAMPUNG BARU KUALA LUMPUR 50250 KUALA LUMPUR W.P KUALA LUMPUR<br />
            Malaysia<br />
            support@streamhost.app
            </p>
        </div>

        <MainFooter/>
    </React.Fragment>

    );
}

export default PrivacyPolicy;
