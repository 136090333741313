import React, { useState } from 'react';
import { MainHeader, MainFooter } from "../components/Layout";
import './css/Posts.css';
import PostItem from './PostItem';
import { DUMMY_POSTS } from '../pages/data.js';

const Posts = () => {
    const [posts, setPosts] = useState(DUMMY_POSTS);
    
    const featuredPost = posts[0];
    const recentPosts = posts.slice(1);

    return (
        <>
            <MainHeader />
            <section className="posts">
                {posts.length > 0 ? (
                    <div className="container posts_container">
                        <h2>StreamHost Blog</h2>
                        {/* Featured post links to PostDetail */}
                        <div className="featured-post">
                            <PostItem
                                key={featuredPost.id}
                                postID={featuredPost.id}
                                thumbnail={featuredPost.thumbnail}
                                category={featuredPost.category}
                                title={featuredPost.title}
                                description={featuredPost.desc}
                                authorID={featuredPost.authorID}
                                isFeatured={true} // Added prop to indicate featured status
                                postLink="/PostDetail" // Link to PostDetail
                            />
                        </div>
                        <h2>Recent articles</h2>
                        <div className="recent-posts">
                            {/* Recent posts link to PostDetail2 */}
                            {recentPosts.map(({ id, thumbnail, category, title, desc, authorID }) => (
                                <PostItem
                                    key={id}
                                    postID={id}
                                    thumbnail={thumbnail}
                                    category={category}
                                    title={title}
                                    description={desc}
                                    authorID={authorID}
                                    postLink="/PostDetail2" // Link to PostDetail2 for recent posts
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <h2 className='center'>No posts found</h2>
                )}
            </section>
            <MainFooter />
        </>
    );
}

export default Posts;
