import React, { useState } from 'react';
import { DUMMY_POSTS } from '../pages/data.js';
import PostItem from '../pages/PostItem.js';
import { MainHeader, MainFooter } from "../components/Layout";

const AuthorPosts = () => {
    const [posts, setPosts] = useState(DUMMY_POSTS);
    
    // Similar to the Posts.js, we'll slice the posts for different link handling
    const featuredPost = posts[0];
    const recentPosts = posts.slice(1);

    return (
        <section className="author-posts">
            <MainHeader />
            {posts.length > 0 ? (
                <div className="container posts_container">
                    {/* Featured post links to PostDetail */}
                    <div className="featured-post">
                        <PostItem
                            key={featuredPost.id}
                            postID={featuredPost.id}
                            thumbnail={featuredPost.thumbnail}
                            category={featuredPost.category}
                            title={featuredPost.title}
                            description={featuredPost.desc}
                            authorID={featuredPost.authorID}
                            postLink="/PostDetail"  // Link to PostDetail for the first post
                        />
                    </div>

                    {/* Recent posts link to PostDetail2 */}
                    <div className="recent-posts">
                        {recentPosts.map(({ id, thumbnail, category, title, desc, authorID }) => (
                            <PostItem
                                key={id}
                                postID={id}
                                thumbnail={thumbnail}
                                category={category}
                                title={title}
                                description={desc}
                                authorID={authorID}
                                postLink="/PostDetail2"  // Link to PostDetail2 for subsequent posts
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <h2 className='center'>No posts found</h2>
            )}
            <MainFooter />
        </section>
    );
}

export default AuthorPosts;
