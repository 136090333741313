import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../api-service";
import './css/PaymentSuccess.css'; 

function Payment() {
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            console.log("Order placed! You will receive an email confirmation.");
        }
        if (query.get("canceled")) {
            console.log("Order canceled -- continue to shop around and checkout when you're ready.");
        }
    }, []);

    return (
        <section>
            <div className="product">
                <div className="description">
                    <h3>Business Package</h3>
                    <h5>RM 300.00</h5>
                </div>
            </div>
            <form action="http://127.0.0.1:8000/api/payment/checkout/" method="POST">
                <button type="submit">Checkout</button>
            </form>
        </section>
    )
}

function PaymentSuccess() {
    const navigate = useNavigate();
    const { session_id } = useParams();
    const getStripeSessionData = async (session_id, user_id) => {
        try {
            const response = await API.addPaymentSession({'session_id': session_id, 'user_id': user_id});
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const params = new URLSearchParams(session_id);

        // Now, you can get the session_id from the params
        const sessionId = params.get("session_id");
        const userId = params.get("user_id");
        getStripeSessionData(sessionId, userId);
    }, []);

    const onClick = () => {
        navigate('/main/link/');
    }

    return (
        <div className="payment-success-container">
            <div className="icon">
                &#10004; 
            </div>
            <h2>Payment Successful!</h2>
            <p>Your payment has been completed.</p>
            <button onClick={onClick}>Back To Home</button>
        </div>
    )
}

export { Payment, PaymentSuccess };
