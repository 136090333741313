import React from 'react';
import { Helmet } from 'react-helmet';
import { MainHeader } from '../components/Layout.js';
import './css/Error.css';
import { Link } from 'react-router-dom';
import ASTRO from '../assets/astro.gif';

function Error() {
    return (
        <div>
            <Helmet>
                <title>Error 404 | StreamHost</title>
            </Helmet>

            <MainHeader/>
            <main className='error_main'>
                <div className='error_details'>
                    <div className='main-details'>
                        <h1>Lost in space?</h1>
                        <div className='big-titles'>
                        <img src = {ASTRO} alt="" />
                        </div>
                        <p>The page you requested could not be found.</p>
                        <li><Link to='/'>Go to Home Page</Link></li>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Error;