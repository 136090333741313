import React, { useState, useContext, useEffect, useRef } from "react";
import {Helmet} from 'react-helmet';
import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCorners, useSensors, useSensor, closestCenter } from '@dnd-kit/core';
import { RiAddLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { arrayMove, sortableKeyboardCoordinates, SortableContext, useSortable, rectSortingStrategy } from '@dnd-kit/sortable';
import { TaskHolder, Task } from "../components/DragAndDrop";
import Sidenav2 from "../components/Sidenav";
import { API } from '../api-service.js';
import { UserContext } from '../user-context.js';
import HelpButton from '../components/HelpButton.js';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import './css/ToDoList.css';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

function TaskTypeCard({ type, selectedTaskType, handleTaskTypeClick, taskCount, startDrag, cancelDrag }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: type });

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition: transition || 'none',
    };

    const handleClick = () => {
        // Always trigger task selection when clicking anywhere on the card, ignore the badge click
        handleTaskTypeClick(type);
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`task-type-card ${selectedTaskType === type ? 'selected' : ''}`}
            onClick={handleClick} // Unified click handling for the whole card
            onMouseDown={() => startDrag(type)}
            onMouseUp={cancelDrag}
            onTouchStart={() => startDrag(type)}
            onTouchEnd={cancelDrag}
            {...attributes}
            {...listeners}
        >
            <span className="task-type-card-content">{type}</span>
            {taskCount > 0 && (
                <div className="task-count-wrapper" onClick={(e) => e.stopPropagation()}>
                    <span className="task-count-badge">
                        {taskCount} 
                    </span>
                </div>
            )}
        </div>
    );
}


function ToDoList() {

    const { userToken, updateTaskCounts } = useContext(UserContext);

    // Reservation tasks
    const [activeReservationBtn, setActiveReservationBtn] = useState('');
    const [activeReservationList, setActiveReservationList] = useState([]);
    const [checkInList, setCheckInList] = useState([]);
    const [checkOutList, setCheckOutList] = useState([]);
    const [currentlyHostingList, setCurrentlyHostingList] = useState([]);
    const [upcomingList, setUpcomingList] = useState([]);
    const [isAddingTaskType, setIsAddingTaskType] = useState(false);
    const [taskType, setTaskType] = useState('');

    // ToDoList tasks
    const [taskTypes, setTaskTypes] = useState(['No Whatsapp']);
    const [customColors, setCustomColors] = useState({'No Whatsapp' : '#653239'});
    const [tasks, setTasks] = useState([]);
    const [taskCounts, setTaskCounts] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const dragTimeout = useRef(null);

    const [selectedTaskType, setSelectedTaskType] = useState('No Whatsapp');
    const [hasUndoneAbove, setHasUndoneAbove] = useState(false);
    const [hasUndoneBelow, setHasUndoneBelow] = useState(false);
    const gridRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const counts = taskTypes.reduce((acc, type) => {
        acc[type] = tasks.filter(task => task.task_type === type).length;
        return acc;
        }, {});

        if (window.updateSidenavTaskCounts) {
            window.updateSidenavTaskCounts(counts);
        }
        setTaskCounts(counts);
    }, [tasks, taskTypes]);

    const handleTaskTypeClick = (type) => {
        if (!isDragging) {
            setSelectedTaskType(type);
        }
    };
    
    const startDrag = (e, type) => {
        // Only start drag after 300ms click-and-hold
        dragTimeout.current = setTimeout(() => {
        setIsDragging(true);  // Activate drag mode
        }, 300);
    };

    const cancelDrag = (e) => {
    // Clear drag timeout
        if (dragTimeout.current) {
            clearTimeout(dragTimeout.current);
        }

        // If the drag wasn't triggered, treat it as a click
        if (!isDragging) {
            let text;
            if (e.target.tagName === 'SPAN') {
                if (e.target.className==='task-count-badge') {
                    text = e.target.offsetParent.firstChild.textContent;
                }else {
                    text = e.target.textContent;
                }
            } else if (e.target.tagName === 'DIV') {
                text = e.target.querySelector('span').textContent;
            }

            console.log(text);
            handleTaskTypeClick(text);
        }

        // Cancel dragging mode
        setTimeout(() => {
            setIsDragging(false);
        }, 0);
    };

    const handleTaskTypeReorder = async (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setTaskTypes((items) => {
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            const newItems = [...items];
            newItems.splice(oldIndex, 1);
            newItems.splice(newIndex, 0, active.id);
        
            // Send the new order to the server (example)
            // fetch("/api/save-task-order", {
            //     method: "POST",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify(newItems),
            // });
        
            return newItems;
            });
        }
    };
      
    
    // Enable scroll horizontally
    useEffect(() => {
        const reservationBody = document.querySelector('.reservation-body');
        if (reservationBody) {
            reservationBody.addEventListener('wheel', (event) => {
                if (event.deltaY !== 0) {
                    event.preventDefault();
                    reservationBody.scrollLeft += event.deltaY;
                }
            });
        }
    }, []);
    
    // Load all the user task data from database
    useEffect(() => {
        setTaskTypes(['No Whatsapp']);
        setTasks([]);
        getTasks(userToken);
        getTaskTypes();
        setActiveReservationBtn('Checking in');
    }, [userToken])
    
    // Finds the index of element with given id
    const getTaskPos = (id) => {
        return tasks.findIndex(task => task.id === id);
    } 

    // Remove task from task button
    const removeTask = (id) => {
        setTasks((tasks) => tasks.filter(task => task.id !== id));

        // Update task status
        const now = new Date();
        let nowISOString = now.toISOString();
        let nowFormatted = nowISOString.replace('T', ' ').replace('Z', '+00:00');
        const data = {
            'token': userToken,
            'id': id,
            'complete_date': nowFormatted,
            'completed': true,
        }
        updateTask(data);
    }

    // Add task from addTask button
    const addTask = (taskTitle, taskType) => {        
        const taskId = 'TASK-' + Date.now();
        const data = {
            'token': userToken,
            'task_id': taskId,
            'task_title': taskTitle,
            'task_type': taskType,
        };
        createTask(data);
    }

    // ------------------------------------------------------------------------

    // API Get, Fetch and Patch function ---------------------------------------------
    // Get tasks
    const getTasks = async (data) => {
        if (userToken) {
            setIsLoading(true);
            try {
                const today = new Date();

                // 1. Get normal tasks
                const taskData = await API.getTasks({'token': data});
                const incompleteTasks = taskData.filter(task => !task.completed);
                console.log("Incomplete tasks:", incompleteTasks);
                setTasks(prevTask => [...prevTask, ...incompleteTasks]);

                const taskCounts = incompleteTasks.reduce((acc, task) => {
                    acc[task.task_type] = (acc[task.task_type] || 0) + 1;
                    return acc;
                }, {});
    
                updateTaskCounts(taskCounts);

                // Get user data
                const clientUserData = await API.getClientUserList(userToken);
                

               // 2. Get check in reservation list
                const checkInList = clientUserData.filter(user => {
                return (user.checkin_datetime ? JSON.parse(user.checkin_datetime.replace(/'/g, '"')) : []).some(checkin => {
                    const date = new Date(checkin);
                    const today = new Date();
                    return date.getFullYear() === today.getFullYear() &&
                        date.getMonth() === today.getMonth() &&
                        date.getDate() === today.getDate();
                });
                }).map(async user => {
                    const checkin_datetime_list = user.checkin_datetime ? JSON.parse(user.checkin_datetime.replace(/'/g, '"')) : [];
                    const checkout_datetime_list = user.checkout_datetime ? JSON.parse(user.checkout_datetime.replace(/'/g, '"')) : [];
                    const building_list = user.chatflow_id ? JSON.parse(user.chatflow_id.replace(/'/g, '"')) : [];
                    const unit_list = user.unit_no ? JSON.parse(user.unit_no.replace(/'/g, '"')) : [];
                    let matchingIndices = [];

                    // Handle if user has multiple bookings on the same day
                    for (let i = 0; i < checkin_datetime_list.length; i++) {
                        const date = new Date(checkin_datetime_list[i]);
                        const today = new Date();
                        if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()) {
                            matchingIndices.push(i);
                        }
                    }
                    let buildingName = [];
                    let unitName = [];
                    let checkInDate = [];
                    let checkOutDate = [];
                    await Promise.all(matchingIndices.map(async index => {
                        const chatflow = await API.getChatFlow({'token': userToken, 'id': building_list[index]});
                        buildingName.push(chatflow.chatflow_name);
                        unitName.push(unit_list[index]);
                        checkInDate.push(formatDateToDDMM(checkin_datetime_list[index]));
                        checkOutDate.push(formatDateToDDMM(checkout_datetime_list[index]));
                    }));

                    return {
                        'name': user.name,
                        'phone_number': user.phone_number,
                        'checkin_datetime': checkInDate,
                        'checkout_datetime': checkOutDate,
                        'building': buildingName,
                        'unitName': unitName
                    };
                });
                const resolvedCheckInList = await Promise.all(checkInList);
                setCheckInList(resolvedCheckInList);
                setActiveReservationList(resolvedCheckInList);


                // 3. Get check out reservation list
                const checkOutList = clientUserData.filter(user => {
                    return (user.checkout_datetime ? JSON.parse(user.checkout_datetime.replace(/'/g, '"')) : []).some(checkout => {
                        const date = new Date(checkout);
                        const today = new Date();
                        return date.getFullYear() === today.getFullYear() &&
                            date.getMonth() === today.getMonth() &&
                            date.getDate() === today.getDate();
                    })
                }).map(async user => {
                    const checkin_datetime_list = user.checkin_datetime ? JSON.parse(user.checkin_datetime.replace(/'/g, '"')) : []
                    const checkout_datetime_list = user.checkout_datetime ? JSON.parse(user.checkout_datetime.replace(/'/g, '"')) : []
                    const building_list = user.chatflow_id ? JSON.parse(user.chatflow_id.replace(/'/g, '"')) : []
                    const unit_list = user.unit_no ? JSON.parse(user.unit_no.replace(/'/g, '"')) : []
                    let matchingIndices = [];

                    // Handle if user has multiple bookings on the same day
                    for (let i = 0; i < checkin_datetime_list.length; i++) {
                        const date = new Date(checkout_datetime_list[i]);
                        const today = new Date();
                        if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()) {
                            matchingIndices.push(i)
                        }
                    }
                    let buildingName = [];
                    let unitName = [];
                    let checkInDate = [];
                    let checkOutDate = [];
                    await Promise.all(matchingIndices.map(async index => {
                        const chatflow = await API.getChatFlow({'token': userToken, 'id': building_list[index]})
                        buildingName.push(chatflow.chatflow_name);
                        unitName.push(unit_list[index]);
                        checkInDate.push(formatDateToDDMM(checkin_datetime_list[index]));
                        checkOutDate.push(formatDateToDDMM(checkout_datetime_list[index]));
                    }))

                    return {
                        'name': user.name,
                        'phone_number': user.phone_number,
                        'checkin_datetime': checkInDate,
                        'checkout_datetime': checkOutDate,
                        'building': buildingName,
                        'unitName': unitName
                    }
                });
                const resolvedCheckOutList = await Promise.all(checkOutList);
                setCheckOutList(resolvedCheckOutList);

                // 4. Get current hosting reservation list
                const hostingList = clientUserData.filter(user => {
                    const checkinDates = user.checkin_datetime ? JSON.parse(user.checkin_datetime.replace(/'/g, '"')) : [];
                    const checkoutDates = user.checkout_datetime ? JSON.parse(user.checkout_datetime.replace(/'/g, '"')) : [];
                
                    return checkinDates.some(checkin => {
                        const checkinDate = new Date(checkin);
                        return checkinDate < today;
                    }) && checkoutDates.some(checkout => {
                        const checkoutDate = new Date(checkout);
                        return checkoutDate > today;
                    });
                }).map(async user => {
                    const checkin_datetime_list = user.checkin_datetime ? JSON.parse(user.checkin_datetime.replace(/'/g, '"')) : []
                    const checkout_datetime_list = user.checkout_datetime ? JSON.parse(user.checkout_datetime.replace(/'/g, '"')) : []
                    const building_list = user.chatflow_id ? JSON.parse(user.chatflow_id.replace(/'/g, '"')) : []
                    const unit_list = user.unit_no ? JSON.parse(user.unit_no.replace(/'/g, '"')) : []
                    let matchingIndices = [];

                    // Handle if user has multiple bookings on the same day
                    for (let i = 0; i < checkin_datetime_list.length; i++) {
                        const checkin_date = new Date(checkin_datetime_list[i]);
                        const checkout_date = new Date(checkout_datetime_list[i]);
                        const today = new Date();
                        if (
                            (checkin_date.getFullYear() <= today.getFullYear() || 
                            (checkin_date.getFullYear() <= today.getFullYear() && checkin_date.getMonth() <= today.getMonth()) || 
                            (checkin_date.getFullYear() <= today.getFullYear() && checkin_date.getMonth() <= today.getMonth() && checkin_date.getDate() <= today.getDate())) &&
                            (checkout_date.getFullYear() >= today.getFullYear() || 
                            (checkout_date.getFullYear() >= today.getFullYear() && checkout_date.getMonth() >= today.getMonth()) || 
                            (checkout_date.getFullYear() >= today.getFullYear() && checkout_date.getMonth() >= today.getMonth() && checkout_date.getDate() >= today.getDate()))
                        ) {
                            matchingIndices.push(i);
                        }
                    }
                    let buildingName = [];
                    let unitName = [];
                    let checkInDate = [];
                    let checkOutDate = [];
                    await Promise.all(matchingIndices.map(async index => {
                        const chatflow = await API.getChatFlow({'token': userToken, 'id': building_list[index]})
                        buildingName.push(chatflow.chatflow_name);
                        unitName.push(unit_list[index]);
                        checkInDate.push(formatDateToDDMM(checkin_datetime_list[index]));
                        checkOutDate.push(formatDateToDDMM(checkout_datetime_list[index]));
                    }))

                    return {
                        'name': user.name,
                        'phone_number': user.phone_number,
                        'checkin_datetime': checkInDate,
                        'checkout_datetime': checkOutDate,
                        'building': buildingName,
                        'unitName': unitName
                    }
                });
                const resolvedCurrentlyHostingList = await Promise.all(hostingList)
                setCurrentlyHostingList(resolvedCurrentlyHostingList);

                // 5. Get future check in list
                const upcomingList = clientUserData.filter(user => {
                    const checkinDates = user.checkin_datetime ? JSON.parse(user.checkin_datetime?.replace(/'/g, '"')) : [];
                
                    return checkinDates.some(checkin => {
                        const checkinDate = new Date(checkin);
                        return checkinDate > today;
                    });
                }).map(async user => {
                    const checkin_datetime_list = user.checkin_datetime ? JSON.parse(user.checkin_datetime?.replace(/'/g, '"')) : []
                    const checkout_datetime_list = user.checkout_datetime ? JSON.parse(user.checkout_datetime?.replace(/'/g, '"')) : []
                    const building_list = user.chatflow_id ? JSON.parse(user.chatflow_id?.replace(/'/g, '"')) : []
                    const unit_list = user.unit_no ? JSON.parse(user.unit_no?.replace(/'/g, '"')) : []
                    let matchingIndices = [];

                    // Handle if user has multiple bookings on the same day
                    for (let i = 0; i < checkin_datetime_list.length; i++) {
                        const date = new Date(checkin_datetime_list[i]);
                        const today = new Date();
                        if (date.getFullYear() >= today.getFullYear() && date.getMonth() >= today.getMonth() && date.getDate() > today.getDate()) {
                            matchingIndices.push(i)
                        }
                    }
                    let buildingName = [];
                    let unitName = [];
                    let checkInDate = [];
                    let checkOutDate = [];
                    await Promise.all(matchingIndices.map(async index => {
                        const chatflow = await API.getChatFlow({'token': userToken, 'id': building_list[index]})
                        buildingName.push(chatflow.chatflow_name);
                        unitName.push(unit_list[index]);
                        checkInDate.push(formatDateToDDMM(checkin_datetime_list[index]));
                        checkOutDate.push(formatDateToDDMM(checkout_datetime_list[index]));
                    }))

                    return {
                        'name': user.name,
                        'phone_number': user.phone_number,
                        'checkin_datetime': checkInDate,
                        'checkout_datetime': checkOutDate,
                        'building': buildingName,
                        'unitName': unitName
                    }
                })
                const resolvedUpcomingList = await Promise.all(upcomingList)
                setUpcomingList(resolvedUpcomingList);

            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }


    // Create tasks
    const createTask = async (data) => {
        try {
            const taskData = await API.addTask(data);
            setTasks(prevTask => [...prevTask, taskData]);
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    const updateTask = async (data) => {
        try {
            const taskData = await API.updateTask(data);
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
    // ------------------------------------------------------------------------

    // API for task typs -----------------------------------------------------
    const getTaskTypes = async () => {
        if (userToken) {
            try {
                const taskTypeData = await API.getTaskTypes({'token': userToken});
                if (taskTypeData.length === 0) {
                    return;
                }
                // Reformat data
                taskTypeData.map(type => {
                    setTaskTypes(prevTypes => [...prevTypes, type.task_type]);
                    if (type.task_color) {
                        setCustomColors(prevColors => ({
                            ...prevColors,
                            [type.task_type]: type.task_color
                        }));
                    }
                });
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        }
    }

    const deleteTaskType = async(task_type) => {
        try {
            setTaskTypes((tasks) => tasks.filter(task => task !== task_type));
            const response = await API.deleteTaskType({'token': userToken, 'task_type': task_type})
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }
    // ------------------------------------------------------------------------


    // Reservation Section logic -----------------------------------------------
    const handleReservationClicked = (type) => {
        const listDict = {
            'Checking in': checkInList,
            'Checking out': checkOutList,
            'Currently hosting': currentlyHostingList,
            'Upcoming': upcomingList
        }
        setActiveReservationBtn(type);
        setActiveReservationList(listDict[type]);
    }
    // ------------------------------------------------------------------------
    


    // DND Logic --------------------------------------------------------------
    // UX: Support pointer, touch, and keyboard movements
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {coordinateGetter: sortableKeyboardCoordinates })
    )

    // Telling dnd what to do when an element is dropped
    const handleDragEnd = (event) => {
        // active is the element currently being dragged
        // over is the element replaced once the element is being let go. Eg: if 1 place on 2, over will return 2
        const {active, over} = event;
        if (active.id === over.id) return; // This means that nothing have changed.

        setTasks((tasks) => {
            // Get position of element before it is dragged
            const originalPos = getTaskPos(active.id);
            const newPos = getTaskPos(over.id);
            // Updates the array based on the position moved
            return arrayMove(tasks, originalPos, newPos);

        })
    }
    // ------------------------------------------------------------------------

    //Add New Task Type -----------------------------------------------------------
    const addNewTaskType = async(newType) => {

        // Task type validation
        if (newType === '') {
            toast.error('Task type cannot be empty');
            return;
        }

        if (taskTypes.includes(newType)) {
            toast.error('Task type already exists');
            return;
        }

        // Add task type to database
        const response = await API.addTaskType({'token': userToken, 'task_type': newType})
        setTaskTypes(prevTypes => [...prevTypes, newType]);
        setIsAddingTaskType(false);
    }

    // Regex function
    function getUserName(input) {
        // Regular expression to match the last number in the string
        const regex = /.*\d+/;
        const match = input?.match(regex);
        
        if (match) {
            // Get the index of the last number
            const lastNumberIndex = match[0].length;
            // Return the substring after the last number
            return input.substring(lastNumberIndex).trim();
        }
        
        // Return the original string if no number is found
        return input;
    }

    function formatDateToDDMM(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        return `${day}/${month}`;
    }

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const checkUndoneTasksVisibility = () => {
        if (!gridRef.current) return;

        const grid = gridRef.current;
        const cards = grid.querySelectorAll('.task-type-card');
        let foundUndoneAbove = false;
        let foundUndoneBelow = false;
        const gridTop = grid.scrollTop;
        const gridBottom = gridTop + grid.clientHeight;

        cards.forEach((card) => {
            const cardTop = card.offsetTop;
            const cardBottom = cardTop + card.clientHeight;
            const taskCount = parseInt(card.querySelector('.task-count-badge')?.textContent || '0');

            if (taskCount > 0) {
                if (cardBottom < gridTop) foundUndoneAbove = true;
                if (cardTop > gridBottom) foundUndoneBelow = true;
            }
        });

        setHasUndoneAbove(foundUndoneAbove);
        setHasUndoneBelow(foundUndoneBelow);
    };

    useEffect(() => {
        const grid = gridRef.current;
        if (grid) {
            grid.addEventListener('scroll', checkUndoneTasksVisibility);
            window.addEventListener('resize', checkUndoneTasksVisibility);
            checkUndoneTasksVisibility();
        }

        return () => {
            if (grid) {
                grid.removeEventListener('scroll', checkUndoneTasksVisibility);
                window.removeEventListener('resize', checkUndoneTasksVisibility);
            }
        };
    }, [taskTypes, taskCounts]);

    const filteredTaskTypes = [...taskTypes]
    .filter((type) => type.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => (taskCounts[b] || 0) - (taskCounts[a] || 0)); 

    const downloadReservationsExcel = () => {
        // Combine all reservation data
        const allReservations = [
            ...checkInList.map(r => ({...r, type: 'Checking In'})),
            ...checkOutList.map(r => ({...r, type: 'Checking Out'})),
            ...currentlyHostingList.map(r => ({...r, type: 'Currently Hosting'})),
            ...upcomingList.map(r => ({...r, type: 'Upcoming'}))
        ];

        // Flatten the data structure
        const flattenedData = allReservations.flatMap(reservation => 
            reservation.checkin_datetime.map((_, index) => ({
                Type: reservation.type,
                Name: reservation.name,
                'Phone Number': reservation.phone_number,
                'Check In': reservation.checkin_datetime[index],
                'Check Out': reservation.checkout_datetime[index],
                Building: reservation.building[index],
                Unit: reservation.unitName[index]
            }))
        );

        // Create a new workbook and add the data
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(flattenedData);
        XLSX.utils.book_append_sheet(wb, ws, "Reservations");

        // Generate and download the Excel file
        XLSX.writeFile(wb, "Reservations.xlsx");
    };

    return (
        <React.Fragment>

            <Helmet>
                <title>StreamHost | To-Do List</title>
            </Helmet>

            <Sidenav2/>
            <div className='todolist-container'>
                <h1>To-Do List</h1>

                {/* Task */}
                <div className='task-container'>
                    <div className='my-list'>
                    <h2>My List</h2>
                    <div className='input-container'>
                        <input type="text" id='taskType' placeholder="Search tasks" value={searchQuery} onChange={handleSearchInputChange} />
                        <label htmlFor='taskType'>Search tasks</label>
                    </div>
                    <div className='task-layout'>
                        {isLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                        ) : (
                        <div>
                            <div className="task-type-grid" ref={gridRef}>
                                {hasUndoneAbove && <div className="undone-indicator top" />}
                                {filteredTaskTypes.map((type) => (
                                    <TaskTypeCard
                                        key={type}
                                        type={type}
                                        selectedTaskType={selectedTaskType}
                                        handleTaskTypeClick={handleTaskTypeClick}
                                        taskCount={taskCounts[type] || 0}
                                        startDrag={startDrag}
                                        cancelDrag={cancelDrag}
                                    />
                                ))}
                                <div className="task-type-card add-task-type-card" onClick={() => setIsAddingTaskType(true)}>
                                    <RiAddLine size={24} />
                                </div>
                                {hasUndoneBelow && <div className="undone-indicator bottom" />}
                            </div>
                        </div>
                        )}
                        </div>
                    </div>
                    <div className='task-list-details'>
                        <div className='task-list-header'>
                            <h2>{`${selectedTaskType}'s task`}</h2>
                            <button onClick={() => deleteTaskType(selectedTaskType)} className='delete-task-type-btn'>
                                <MdDelete color="red" />
                            </button>
                        </div>
                        <div className='task-details'>
                                {tasks.filter(task => task.task_type === selectedTaskType).length === 0 && (
                                    <p className="no-tasks-message">Please add a new task</p>
                                )}
                            <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                                <TaskHolder 
                                    tasks={tasks.filter(task => task.task_type === selectedTaskType)} 
                                    removeTask={removeTask} 
                                    addTask={addTask}
                                    taskType={selectedTaskType}
                                    customColors={customColors}
                                    setCustomColors={setCustomColors}
                                />
                            </DndContext>
                        </div>
                    </div>
                </div>
                {isAddingTaskType && (
                    <div className='add-task-type-modal'>
                        <h3>Add new task type</h3>
                        <input type='text' placeholder='Task title' maxLength={40} value={taskType} onChange={(evt) => setTaskType(evt.target.value)}/>
                        <div className='add-task-type-btn-container'>
                            <button onClick={() => setIsAddingTaskType(false)}>Cancel</button>
                            <button onClick={() => addNewTaskType(taskType)}>Confirm</button>
                        </div>
                    </div>
                )}
            </div>
                
            <div className='reservation-container'>
                
                <h2>Your reservations</h2>
                <div className='reservation-header'>
                    <button onClick={() => handleReservationClicked('Checking in')} style={{'border': activeReservationBtn === 'Checking in' ? 'solid 1.6px #002244' : undefined}}>Checking in ({checkInList.length})</button>
                    <button onClick={() => handleReservationClicked('Checking out')} style={{'border': activeReservationBtn === 'Checking out' ? 'solid 1.6px #002244' : undefined}}>Checking out ({checkOutList.length})</button>
                    <button onClick={() => handleReservationClicked('Currently hosting')} style={{'border': activeReservationBtn === 'Currently hosting' ? 'solid 1.6px #002244' : undefined}}>Currently hosting ({currentlyHostingList.length})</button>
                    <button onClick={() => handleReservationClicked('Upcoming')} style={{'border': activeReservationBtn === 'Upcoming' ? 'solid 1.6px #002244' : undefined}}>Upcoming ({upcomingList.length})</button>
                    <button onClick={downloadReservationsExcel} style={{backgroundColor:'#002244', color: '#FFF'}}>Download excel</button>
                </div>

                <div className='reservation-body'>
                    {activeReservationList.length > 0 ? (
                        activeReservationList.map((reservation, index) => {
                            return (
                                <div className='reservation-card' key={reservation.id || index}>
                                    <div className='reservation-name-section'>
                                        <h3>{getUserName(reservation.name)}</h3>
                                        <p>+{reservation.phone_number}</p>
                                    </div>
                                    <hr style={{ borderColor: 'rgba(0, 0, 0, 0.3)', borderWidth: '0.5px' }} />
                                    <div className='reservation-building-section'>
                                        {reservation.checkin_datetime && reservation.checkout_datetime && reservation.building && reservation.unitName ? (
                                            reservation.checkin_datetime.length > 0 ? (
                                                reservation.checkin_datetime.map((checkin, idx) => (
                                                    <div className='reservation-building' key={`${reservation.id}-${idx}`}>
                                                        <h3>{checkin} - {reservation.checkout_datetime[idx]}</h3>
                                                        <p>{reservation.building[idx]}: {reservation.unitName[idx]}</p>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No check-in data available</p>
                                            )
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className='reservation-card empty'>
                            <p>You're all done for today</p>
                        </div>
                    )}
                    </div>
                </div>
            
            <ToastContainer position='bottom-center' autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            <HelpButton />
        </React.Fragment>
    );
};

export default ToDoList;
