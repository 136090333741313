import { useState, useRef, useEffect } from 'react'; 
import { MainHeader, MainFooter } from "../components/Layout";
import './css/FAQ.css';
import { Helmet } from 'react-helmet';
import { BsFillMoonStarsFill } from "react-icons/bs";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import { Mic, Square, Download } from 'lucide-react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
function FAQ() {
    const [answersVisible, setAnswersVisible] = useState([false, false, false, false, false]);

    const toggleAnswer = (index) => {
        const updatedVisibility = [...answersVisible];
        updatedVisibility[index] = !updatedVisibility[index];
        setAnswersVisible(updatedVisibility);
    };
    const VoiceRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    const [error, setError] = useState('');
    const [isConverting, setIsConverting] = useState(false);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const ffmpegRef = useRef(null);

    useEffect(() => {
        // Load FFmpeg
        const loadFFmpeg = async () => {
        try {
            const ffmpeg = new FFmpeg();
            ffmpegRef.current = ffmpeg;
            await ffmpeg.load({
            coreURL: await toBlobURL(`/ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`/ffmpeg-core.wasm`, 'application/wasm'),
            });
            console.log('FFmpeg loaded');
        } catch (error) {
            console.error('Error loading FFmpeg:', error);
            setError('Error loading audio conversion tool. Please try again later.');
        }
        };
        loadFFmpeg();
    }, []);

    const startRecording = async () => {
        try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorder.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });

        mediaRecorder.current.ondataavailable = (event) => {
            audioChunks.current.push(event.data);
        };

        mediaRecorder.current.onstop = async () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
            const mp3Blob = await convertWebmToMp3(audioBlob);
            const audioUrl = URL.createObjectURL(mp3Blob);
            setAudioURL(audioUrl);
            setIsConverting(false);
        };

        audioChunks.current = [];
        mediaRecorder.current.start();
        setIsRecording(true);
        } catch (err) {
        setError('Error accessing microphone: ' + err.message);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder.current) {
        mediaRecorder.current.stop();
        setIsRecording(false);
        setIsConverting(true);
        }
    };

    const convertWebmToMp3 = async (webmBlob) => {
        const ffmpeg = ffmpegRef.current;
        if (!ffmpeg) {
        throw new Error('FFmpeg not loaded');
        }

        const inputName = 'input.webm';
        const outputName = 'output.mp3';

        await ffmpeg.writeFile(inputName, await fetchFile(webmBlob));

        await ffmpeg.exec(['-i', inputName, outputName]);

        const outputData = await ffmpeg.readFile(outputName);
        const outputBlob = new Blob([outputData], { type: 'audio/mp3' });
        console.log(outputBlob);

        return outputBlob;
    };

    const downloadAudio = () => {
        const a = document.createElement('a');
        a.href = audioURL;
        a.download = 'recorded_audio.mp3';
        a.click();
    };

    return (
        <div className="flex flex-col items-center space-y-4 p-4">
        <div className="flex space-x-2">
            <button
            onClick={isRecording ? stopRecording : startRecording}
            className={isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'}
            disabled={isConverting || !ffmpegRef.current}
            >
            {isRecording ? <Square className="mr-2" /> : <Mic className="mr-2" />}
            {isRecording ? 'Stop Recording' : 'Start Recording'}
            </button>
            {audioURL && (
            <button onClick={downloadAudio} className="bg-green-500 hover:bg-green-600" disabled={isConverting}>
                <Download className="mr-2" />
                Download MP3
            </button>
            )}
        </div>
        {audioURL && (
            <audio controls src={audioURL} className="w-full max-w-md">
            Your browser does not support the audio element.
            </audio>
        )}
        {isConverting && <p>Converting to MP3...</p>}
        {error && (
            <p>{error}</p>
        )}
        </div>
    );
    };
        

    return (
        <div>
            <Helmet>
                <title>Frequently Asked Questions | StreamHost</title>
            </Helmet>

            <MainHeader/>
            <main className='faq_main'>
                <VoiceRecorder/>
                <section className="faq">
                    <div className="faq_title">
                        <BsFillMoonStarsFill />
                        <h1>Frequently Asked Questions</h1>
                    </div>

                    <div className={`faq_item ${answersVisible[0] ? 'expanded' : ''}`}>
                        <div className="faq_question" onClick={() => toggleAnswer(0)}>
                            <h1>What is StreamHost, and how will it help my business?</h1>
                            {answersVisible[0] ? <FaCircleMinus /> : <FaCirclePlus />}
                        </div>
                        <div className={`faq_answer ${answersVisible[0] ? 'visible' : ''}`}>
                            <p>StreamHost is a platform designed and built with Short Rental Hosts in mind. It provides features like language translation, customizable automated reply, schedule message, to do list, and dashboard which shows user sentiments (Satisfaction value) by using Machine Learning techniques. This allows hosts to have higher efficiency and increased customer satisfaction.</p>
                        </div>
                    </div>

                    <div className={`faq_item ${answersVisible[1] ? 'expanded' : ''}`}>
                        <div className="faq_question" onClick={() => toggleAnswer(1)}>
                            <h1>Does StreamHost integrate with WhatsApp?</h1>
                            {answersVisible[1] ? <FaCircleMinus /> : <FaCirclePlus />}
                        </div>
                        <div className={`faq_answer ${answersVisible[1] ? 'visible' : ''}`}>
                            <p>Yes, of course! StreamHost is a qualified WhatsApp Business Solution Provider (BSP) which integrates your phone number by using the official WhatsApp API. We provide features based on different tiers. Go ahead and contact our customer support to find out which package fits your business goal!</p>
                        </div>
                    </div>

                    <div className={`faq_item ${answersVisible[2] ? 'expanded' : ''}`}>
                        <div className="faq_question" onClick={() => toggleAnswer(2)}>
                            <h1>What is a WhatsApp Business chatbot?</h1>
                            {answersVisible[2] ? <FaCircleMinus /> : <FaCirclePlus />}
                        </div>
                        <div className={`faq_answer ${answersVisible[2] ? 'visible' : ''}`}>
                            <p>A WhatsApp Business chatbot is an automated tool that interacts with customers on WhatsApp, providing quick responses, handling queries, and assisting with various tasks, enhancing customer engagement and service efficiency.</p>
                        </div>
                    </div>

                    <div className={`faq_item ${answersVisible[3] ? 'expanded' : ''}`}>
                        <div className="faq_question" onClick={() => toggleAnswer(3)}>
                            <h1>Is the chatbot available 24/7?</h1>
                            {answersVisible[3] ? <FaCircleMinus /> : <FaCirclePlus />}
                        </div>
                        <div className={`faq_answer ${answersVisible[3] ? 'visible' : ''}`}>
                            <p>Yes, our chatbot operates round-the-clock, ensuring that your customers can receive support and information at any time of day or night.</p>
                        </div>
                    </div>

                    <div className={`faq_item ${answersVisible[4] ? 'expanded' : ''}`}>
                        <div className="faq_question" onClick={() => toggleAnswer(4)}>
                            <h1>Does StreamHost provide a free trial?</h1>
                            {answersVisible[4] ? <FaCircleMinus /> : <FaCirclePlus />}
                        </div>
                        <div className={`faq_answer ${answersVisible[4] ? 'visible' : ''}`}>
                            <p>Yes, we offer a free trial period for 30 days so you can experience the benefits of our chatbot solution before committing to a paid plan.</p>
                        </div>
                    </div>

                    <div className={`faq_item ${answersVisible[5] ? 'expanded' : ''}`}>
                        <div className="faq_question" onClick={() => toggleAnswer(5)}>
                            <h1>Can I cancel anytime?</h1>
                            {answersVisible[5] ? <FaCircleMinus /> : <FaCirclePlus />}
                        </div>
                        <div className={`faq_answer ${answersVisible[5] ? 'visible' : ''}`}>
                            <p>Plans can be cancelled at any time without incurring any cancellation fees and will no longer be renewed at the next renewal date. All data stored on your accounts will also be erased within 3 months or upon request.</p>
                        </div>
                    </div>
                </section>
            </main>

            <MainFooter/>
        </div>
    );
}

export default FAQ;
