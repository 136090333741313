import React from 'react';
import { FaImage, FaVideo, FaFile } from 'react-icons/fa';
import './css/MobileImageSelector.css';

const MobileImageSelector = ({ onFileSelect }) => {
    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            onFileSelect(file, type);
        }
    };

    return (
        <div className="mobile-image-selector">
            <label className="file-type-button">
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, 'image')}
                    style={{ display: 'none' }}
                />
                <FaImage /> Images
            </label>
            <label className="file-type-button">
                <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleFileChange(e, 'video')}
                    style={{ display: 'none' }}
                />
                <FaVideo /> Videoa
            </label>
            <label className="file-type-button">
                <input
                    type="file"
                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                    onChange={(e) => handleFileChange(e, 'document')}
                    style={{ display: 'none' }}
                />
                <FaFile /> Documents
            </label>
        </div>
    );
};

export default MobileImageSelector;