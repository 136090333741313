import React, {useState, useEffect, useRef, useContext} from "react";
import { UserContext } from "../user-context";
import { API } from "../api-service";
import ChatArea from '../components/ChatArea';
import { useParams, useNavigate } from 'react-router-dom';

import './css/StreamhostInbox.css';

function StreamHostInbox () {

    const [text, setText] = useState('');
    const [userToken, setUserToken] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [headerName, setHeaderName] = useState('');
    const socketRef = useRef();
    const [textAreaHeight, setTextAreaHeight] = useState('auto');
    const [audioPreviewUrl, setAudioPreviewUrl] = useState(null);
    const [chatRoomDetails, setChatRoomDetails] = useState([]);
    const [chatRoomDetails2, setChatRoomDetails2] = useState([]); //dummy state to force re-render one time
    const [currentParticipantPhoneNumber, setCurrentParticipantPhoneNumber] = useState();
    const [replyInfo, setReplyInfo] = useState(null);
    const [MessageToChatArea, setMessageToChatArea] = useState(null);
    const [ImageToChatArea, setImageToChatArea] = useState('');
    const [AudioToChatArea, setAudioToChatArea] = useState(null);
    const [replyText, setReplyText] = useState(null);
    const [captionInput, setCaptionInput] = React.useState('');
    const [isSendingFile, setIsSendingFile] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [currentMessages, setCurrentMessages] = useState([]);
    const [showRefreshMessage, setShowRefreshMessage] = useState(false);

    const [hasMore, setHasMore] = useState();
    const [page, setPage] = useState(1);
    const [isConnectionLost, setIsConnectionLost] = useState(false);
    const [chatRoomId, setChatRoomId] = useState(null);
    const { user_id, phone_number, wabaId } = useParams();


    useEffect(() => {
        const getChatRoomData = async() => {
            let token = await API.getToken({'user_id': user_id});
            setUserToken(token['token']);

            let client_user = await API.getClientUser({'token': token['token'], 'phone_number': phone_number.replace(/\s+/g, '').replace('+', '')});
            console.log('CLIENT USER GET')
            console.log(client_user)
            // get Chatroom data
            let chatroom = await API.getChatRoom({'token': token['token'], 'user_id': user_id, 'phone_number': phone_number.replace(/\s+/g, '').replace('+', ''), 'waba_id': wabaId});
            console.log('GET CHATROOM');
            console.log(chatroom);
            if ('error' in chatroom) {
                chatroom = await API.addChatRoom({'token': token['token'], 'client_user_id': client_user['id'], 'chatroom_type': 'streamhost', 'waba_id': wabaId})
                console.log('CREATE CHATROOM');
                console.log(chatroom);
            }
            setChatRoomId(chatroom['id']);

            const waba_list = await API.getWabaId(token['token']);
            const waba = waba_list.find(waba => waba.waba_id === wabaId);
            setHeaderName(waba.verified_name);

        }
        getChatRoomData();
    }, [])

    useEffect(() => {
        console.log('CHATROOM ID');
        console.log(chatRoomId);
        setCurrentMessages([]); 
        let timeoutId;
        setShowRefreshMessage(false);
        setHasMore(false);
        setPage(1);

        console.time('🔌 WebSocket Connection');
        const connectStart = performance.now();
        
        socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chat/788/`);
        
        socketRef.current.onopen = () => {
            const connectEnd = performance.now();
            console.timeEnd('🔌 WebSocket Connection');
            console.log(`✨ WebSocket connected in ${(connectEnd - connectStart).toFixed(2)}ms`);
            
            setIsConnectionLost(false);
            
            setInterval(function() {
                if (socketRef.current.readyState === WebSocket.OPEN) {
                    socketRef.current.send(JSON.stringify({ 
                        message: 'heartbeat', 
                        senderId: wabaId, 
                        chatRoomId: chatRoomId, 
                        message_type: "heartbeat"
                    }));
                }
            }, 60000);
        };

        fetchChatRoomMessages(chatRoomId, userToken);
        
        socketRef.current.onerror = (event) => {
            console.error('WebSocket error:', event);
        };
        

        socketRef.current.onclose = function(event) {   
            if (event.wasClean) {
              console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
              setIsConnectionLost(true);
              timeoutId = setTimeout(() => {
                    setShowRefreshMessage(true);
                }, 10000);
            } else {
              // e.g. server process killed or network down
              // event.code is usually 1006 in this case
              console.log('[close] Connection died');
              setIsConnectionLost(true);
              timeoutId = setTimeout(() => {
                    setShowRefreshMessage(true);
                }, 10000);
            }
          };
          

        // Clean up the WebSocket connection when the component unmounts or the chat room ID changes
        return () => {
            console.log('🔌 Closing WebSocket connection');
            socketRef.current.close();
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
            
    }, [chatRoomId, userToken, wabaId]);

    useEffect(() => {
        
        if (socketRef.current) {
            socketRef.current.onmessage = (event) => {
                const message = JSON.parse(event.data);
                const currentTime = new Date();
                const baseUrl = "https://streamhost-media.s3.ap-southeast-2.amazonaws.com/";
                const formattedCurrentTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
               
                
                // Check message type and prepend URL if it's a file
                let messageType = message.message_type;

                let messageContent = message.message;
                // Process message content if it contains a file extension
                if (typeof messageContent === 'string') {
                    const lastDotIndex = messageContent.lastIndexOf('.');
                    if (lastDotIndex !== -1 && messageContent.charAt(lastDotIndex - 1) === '=') {
                        // Remove the '=' before the extension
                        messageContent = messageContent.slice(0, lastDotIndex - 1) + messageContent.slice(lastDotIndex);
                    }
                }

                if (message.message === 'heartbeat') {
                    return;
                }

                else if (messageType === 'text') {
                    
                    const newMessage = {
                        text: messageContent,
                        senderId: message.senderId,
                        timestamp: formattedCurrentTime,
                        media_type: messageType,
                        wamid: message.wamid,
                        reply_to: message.reply_to ? {
                            text: message.reply_to.text,
                            media_type: message.reply_to.media_type,
                            wamid: message.reply_to.wamid
                        } : null
                    };
                    setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
                }
                else {
                    const newMessage = {
                        text: null,
                        senderId: message.senderId,
                        timestamp: formattedCurrentTime,
                        media_type: messageType,
                        image: baseUrl + messageContent,
                        wamid: message.wamid,
                        reply_to: message.reply_to ? {
                            text: message.reply_to.text,
                            media_type: message.reply_to.media_type,
                            wamid: message.reply_to.wamid
                        } : null,
                        caption: message.caption
                    };

                    setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
                }
              
            };
        }
    }, [currentParticipantPhoneNumber, chatRoomId, userToken]);

    const handleReply = (message) => {
        if (message === null) {
            setReplyText(null);
            setReplyInfo(null);
        } else {
            setReplyInfo(message);
            setReplyText(message.text || message.media_type);
        }
    };

    const fetchChatRoomMessages = async(chatRoomId, userToken) => {
        console.log('CHATROOM ID');
        console.log(chatRoomId);
        const startTime = performance.now();
        try {
            console.time('📨 Fetching Messages');
            const response = await API.getChatRoomMessagesNew(chatRoomId, userToken, 1);
            console.log(response);
            setChatRoomDetails(response.messages ?? []);
            setChatRoomDetails2(response.messages ?? []); 
            setHasMore(response.next);
            setCurrentParticipantPhoneNumber(response.participant_phone_numbers);

            const endTime = performance.now();
            console.timeEnd('📨 Fetching Messages');
            console.log(`✨ Loaded ${response.messages?.length || 0} messages in ${(endTime - startTime).toFixed(2)}ms`);
        } catch (error) {
            console.error('❌ Error loading messages:', error);
        }
    }

    const sendStreamHostMessage = async () => {
        await API.saveChatRoomMessages({
            message: text,
            client_id: 588,
            chatRoomId: chatRoomId,
            userToken: userToken,
            wamId: null,
            type: 'streamhost',
            reply_to_id: null
        });
    }

    return (
        <div className="streamhost-inbox">
            <div className="inbox-header2">
                <div className='main-inbox-header'>
                    <h3>{headerName}</h3>
                </div>
            </div>
            <div className="streamhost-inbox-chat-area">
                <ChatArea 
                    chatRoomId={chatRoomId} 
                    MessageToChatArea={MessageToChatArea} 
                    ImageToChatArea={ImageToChatArea} 
                    AudioToChatArea={AudioToChatArea} 
                    socketRef={socketRef} 
                    replyText={replyText}
                    isSendingFile={isSendingFile} 
                    setIsSendingFile={setIsSendingFile} 
                    setSelectedFile={setSelectedFile} 
                    setPreviewUrl={setPreviewUrl}
                    onReply={handleReply}
                    setCaptionInput={setCaptionInput}
                    captionInput={captionInput}
                    user={'clientUser'}
                />
            </div>

             {/* Text area */}
            <div className='streamhost-textarea'>
                <textarea type="text" placeholder='test' value={text} onChange={(e) => setText(e.target.value)} style={{ display: audioPreviewUrl ? 'none' : 'block' ,height: textAreaHeight, maxHeight: '200px', fontSize: '1rem'}} className='chat-box-form-input-box' inputMode="text"
                    onKeyDown={async(event) => {
                        if (event.key === 'Enter' && isMobile) {
                            event.preventDefault();
                        }else if (event.key === 'Enter' && !event.shiftKey && !event.nativeEvent.isComposing && !isMobile) {
                            event.preventDefault();
                            sendStreamHostMessage()
                            setText('');
                        }
                    }
                }/>
            </div>
            <div className='streamhost-inbox-div'>
                <button className='streamhost-send' onClick={() => sendStreamHostMessage()}>Send</button>
            </div>
        </div>
    )
}

export {StreamHostInbox}