
import Sidenav2 from '../components/Sidenav.js';
import React, {useEffect, useState, useContext} from 'react';
import './css/Broadcast.css';
import { Helmet } from 'react-helmet';
import { UserContext } from '../user-context.js';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import { API } from '../api-service.js';
import { FiFilter } from 'react-icons/fi';

function Broadcast() {
    const [activeView, setActiveView] = useState('settings');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [isLoading, setIsLoading] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const { userToken, profileId, userId, wabaId, SystemUserToken, waPhoneNumberId } = useContext(UserContext);

    const [whatsappImageUrl, setWhatsappImageUrl] = useState(null);
    
    // Filter states
    const [selectedBuilding, setSelectedBuilding] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('');
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [buildingUnitMap, setBuildingUnitMap] = useState({});

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        fetchTemplates();
        fetchContacts();
    }, [userToken]);

    useEffect(() => {
        const buildingMap = {};
        const buildings = new Set();
    
        contacts.forEach(contact => {
          if (contact.building && Array.isArray(contact.building)) {
            contact.building.forEach(building => {
              if (building) {
                buildings.add(building);
                if (!buildingMap[building]) {
                  buildingMap[building] = new Set();
                }
                if (contact.unit_no) {
                  buildingMap[building].add(contact.unit_no);
                }
              }
            });
          }
        });
    
        setBuildingOptions([...buildings].sort());
        setBuildingUnitMap(buildingMap);
      }, [contacts]);
    
      useEffect(() => {
        if (selectedBuilding) {
          const units = buildingUnitMap[selectedBuilding] || new Set();
          setUnitOptions([...units].sort());
        } else {
          setUnitOptions([]);
          setSelectedUnit('');
        }
      }, [selectedBuilding, buildingUnitMap]);
    
      useEffect(() => {
        let filtered = [...contacts];
    
        if (selectedBuilding) {
          filtered = filtered.filter(contact => {
            if (!contact.building || !Array.isArray(contact.building)) return false;
            
            const hasBuilding = contact.building.includes(selectedBuilding);
            
            if (selectedUnit) {
              return hasBuilding && contact.unit_no === selectedUnit;
            }
            
            return hasBuilding;
          });
        }
    
        setFilteredContacts(filtered);
      }, [contacts, selectedBuilding, selectedUnit]);

    const handleTemplateChange = (evt) => {
        const selectedTemp = templateList.find(t => t.name === evt.target.value);
        setSelectedTemplate(selectedTemp);
        setWhatsappImageUrl(null);

    };


    const handleBroadcast = async () => {
        if (!selectedTemplate) {
            toast.error('Please select a template');
            return;
        }
        if (selectedContacts.length === 0) {
            toast.error('Please select at least one contact');
            return;
        }
    
        setIsSending(true);
        let successCount = 0;
        let failCount = 0;
    

        try {
            for (const contact of selectedContacts) {

                const templateMessage = {
                    wabaId: wabaId,
                    waPhoneNumberId: waPhoneNumberId,
                    SystemUserToken: SystemUserToken,
                    toWaNumber: [contact.phone_number.replace(/\s+/g, '').replace('+', '')],
                    templateName: selectedTemplate.name,
                    imageUrl: whatsappImageUrl || null,
                    chatRoomId: null
                };




                try {
                    const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/send-whatsapp-template/', {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Token ' + userToken,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(templateMessage),
                    });
    
                    const data = await response.json();
                    if (response.ok) {

                        successCount++;
                    } else {

                        failCount++;
                    }
                } catch (error) {

                    failCount++;
                }
            }
    
            if (successCount > 0) {
                toast.success(`Successfully sent to ${successCount} contacts`);
            }
            if (failCount > 0) {
                toast.error(`Failed to send to ${failCount} contacts`);
            }
    

            setSelectedTemplate('');
            setWhatsappImageUrl(null);
            setSelectedContacts([]);
    
        } catch (error) {

            toast.error('Broadcast failed');
        } finally {
            setIsSending(false);
        }
    };

    const fetchTemplates = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('https://graph.facebook.com/v20.0/'+ wabaId +'/message_templates?fields=name,components,status', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + SystemUserToken,
                }
            });
            const data = await response.json();
            setTemplateList(data.data.filter(template => template.status === 'APPROVED'));
        } catch (error) {

            toast.error('Failed to fetch templates');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchContacts = async () => {
        setIsLoading(true);
        try {
            const data = await API.getClientUserList(userToken);
            const formattedData = await Promise.all(data?.map(async (contact) => {
                let chatflowIds = [];
                let checkinDates = [];
                let checkoutDates = [];
                let checkin_list = [];
                let checkout_list = [];

                try {
                    chatflowIds = JSON.parse(contact.chatflow_id || '[]');
                    checkinDates = JSON.parse(contact.checkin_datetime.replace(/'/g, '"') || '[]');
                    checkoutDates = JSON.parse(contact.checkout_datetime.replace(/'/g, '"') || '[]');
                    checkin_list = JSON.parse(contact.checkin_list.replace(/'/g, '"') || '[]');
                    checkout_list = JSON.parse(contact.checkout_list.replace(/'/g, '"') || '[]');
                } catch (error) {
                    console.error('Error parsing contact data:', error);
                }

                const buildingArray = await Promise.all(
                    chatflowIds.map(async (chatflow_id) => {
                        const chatflow = await API.getChatFlow({ 'token': userToken, 'id': chatflow_id });
                        return chatflow.chatflow_name;
                    })
                );

                return {
                    id: contact.id,
                    name: contact.name,
                    phone_number: contact.phone_number,
                    building: buildingArray,
                    unit_no: contact.unit_no,
                    checkin_status: contact.checkin_status,
                    checkout_status: contact.checkout_status,
                    checkin_list: checkin_list,
                    checkout_list: checkout_list,
                    checkin_datetime: checkinDates,
                    checkout_datetime: checkoutDates,
                    chatflow_id: chatflowIds,
                };
            }));
            setContacts(formattedData);
            setFilteredContacts(formattedData);
        } catch (error) {

            toast.error('Failed to fetch contacts');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancelFilter = () => {
        setSelectedBuilding('');
        setSelectedUnit('');
    };
    
    const handleSelectAll = () => {
        setSelectedContacts([...filteredContacts]);
    };

    const handleCancelAll = () => {
        setSelectedContacts([]);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Broadcast</title>
            </Helmet>
            <Sidenav2/>
            <div className='broadcast-main'>

                <h1>Broadcast Messages</h1>
                <div className="broadcast-container">
                    <div className="broadcast-section">
                        <h2>Select Template</h2>
                        <select 
                            value={selectedTemplate?.name || ''} 
                            onChange={handleTemplateChange}
                            className="template-select"
                        >
                            <option value="">Select a template</option>
                            {templateList.map((template) => (
                                <option key={template.name} value={template.name}>
                                    {template.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="broadcast-section">
                        <h2>Select Contacts</h2>
                        <div className="filter-section">
                            <div className="filter-group">
                                <div className="filter-controls">
                                <select value={selectedBuilding} onChange={e => {setSelectedBuilding(e.target.value);setSelectedUnit('');}} className="broad-filter-select">
                                    <option value="">All Buildings</option>
                                    {buildingOptions.map((building) => (
                                    <option key={building} value={building}>
                                        {building}
                                    </option>
                                    ))}
                                </select>

                                <select value={selectedUnit} onChange={e => setSelectedUnit(e.target.value)}className="broad-filter-select" disabled={!selectedBuilding} >
                                    <option value="">All Units</option>
                                    {unitOptions.map((unit) => (
                                    <option key={unit} value={unit}>
                                        {unit}
                                    </option>
                                    ))}
                                </select>

                                <button onClick={handleCancelFilter} className="filter-button cancel" disabled={!selectedBuilding}>
                                    Cancel Filter
                                </button>
                            </div>

                            <div className="selection-buttons">
                                <button onClick={handleSelectAll} className="filter-button select-all">
                                    Select All
                                </button>

                                <button onClick={handleCancelAll} className="filter-button cancel-all" disabled={selectedContacts.length === 0}>
                                        Cancel All
                                </button>
                            </div>
                            </div>
                        </div>
                        
                        {isLoading ? (
                            <div className="loader-container">
                            <div className="loader"></div>
                            </div>
                        ) : (
                            <div className="contacts-grid">
                            {filteredContacts.map((contact) => (
                                <div 
                                key={contact.id} 
                                className={`contact-item ${selectedContacts.includes(contact) ? 'selected' : ''}`}
                                onClick={() => {
                                    setSelectedContacts(prev => 
                                    prev.includes(contact) 
                                        ? prev.filter(c => c !== contact)
                                        : [...prev, contact]
                                    );
                                }}
                                >
                                <input 
                                    type="checkbox" 
                                    checked={selectedContacts.includes(contact)}
                                    readOnly
                                />
                                <span>{contact.name}</span>
                                </div>
                            ))}
                            </div>
                        )}
                        </div>


                    <div className="broadcast-actions">
                        <button 
                            onClick={handleBroadcast}
                            disabled={isSending || !selectedTemplate || selectedContacts.length === 0}
                            className="broadcast-button"
                        >
                            {isSending ? 'Sending...' : 'Send Broadcast'}
                        </button>
                    </div>
                </div>
                
            </div>
            <ToastContainer 
                position='bottom-center' 
                autoClose={5000} 
                hideProgressBar={false} 
                closeOnClick 
                pauseOnFocusLoss 
                draggable 
                rtl={false} 
                pauseOnHover 
                theme='colored' 
                transition={Bounce} 
            />
        </React.Fragment>
    );
}

export default Broadcast;