import React, { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from "../user-context";
import Sidenav from "../components/Sidenav";
import { API } from "../api-service";
import './css/Notifications.css';
import {Helmet} from 'react-helmet';
import { RiDeleteBin5Line } from "react-icons/ri";
import { SlSettings } from "react-icons/sl";
import UserImage from '../assets/user.png';

function Notifications() {
    const { userToken, wabaId, profileId} = useContext(UserContext);
    const [notifications, setNotifications] = useState([]);
    const [newNotifications, setNewNotifications] = useState([]);
    const [toggleNotification, setToggleNotification] = useState(false);
    const socket = useRef(null);

    function formatTimestampToDateAnd12Hour(timeString) {
        const date = new Date(timeString);
        return date.toLocaleString('en-US', { 
            month: 'numeric', 
            day: 'numeric', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: true 
        });
    }

    function formatTimestampTo12Hour(timeString) {
        const date = new Date(timeString);
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    }

    useEffect(() => {

        API.getNotifications(userToken, wabaId)
            .then((notifications) => {
                const formattedNotifications = notifications.map(notification => {
                const formattedTimestamp = formatTimestampToDateAnd12Hour(notification.message.timestamp);
                return { ...notification, message: { ...notification.message, id:notification.id, timestamp: formattedTimestamp } };
            });
                setNotifications(formattedNotifications);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
      }, [userToken, wabaId]);



    useEffect(() => {
        if (!toggleNotification) {
            const wabaIdLink = localStorage.getItem('waba_id')
            socket.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/notifications/${profileId}/`);
            setToggleNotification(true);
        }
        
        // console.log("notification websocket ", socket.current);
        if (socket.current) {
            socket.current.onmessage = (event) => {
                
                const messageData = JSON.parse(event.data);
                console.log(messageData)
                const newNotification = {
                    clientWabaId: messageData.clientWabaId,
                    message: messageData.message,
                    name: messageData.message_sender,
                };
                setNewNotifications(currentNotifications => [...currentNotifications, newNotification]);
            };
        }

        // Cleanup function to close the WebSocket connection
        return () => {
            if (socket.current) {
                socket.current.close();
                console.log("WebSocket closed");
                setToggleNotification(false);
                socket.current = null;
            }
        };
    }, []); // This effect does not depend on `socket` directly

    
    const handleDeleteSelected = async() => {
        const notification = await API.getNotifications(userToken, wabaId);
        console.log("notification ", notification);
        notification.map(async (notif) => {
            await API.deleteNotification({'token': userToken, 'id': notif.id});
        })
      
        setNewNotifications([]);
        setNotifications([]);
    };

    const [expandedUsers, setExpandedUsers] = useState({});

    const normalizedNewNotifications = newNotifications.map((notification, index) => ({
        message: {
            sender: {
                name: notification?.name,
                profilePicture: notification.profilePicture
            },
            text: notification.message,
            timestamp: new Date().toLocaleTimeString('en-US', { 
                month: 'numeric', 
                day: 'numeric', 
                year: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true 
            })
            
        },
        isNew: true,
        index: notifications.length + index
    }));

    const allNotifications = [...normalizedNewNotifications.reverse(), ...notifications];

    

    const toggleExpand = (userName) => {
        setExpandedUsers(prev => ({
            ...prev,
            [userName]: !prev[userName]
        }));
    };

    const groupNotificationsByDate = (notifications) => {
        const today = new Date().setHours(0, 0, 0, 0);
        const yesterday = new Date(today - 86400000).setHours(0, 0, 0, 0);
      
        return notifications.reduce((acc, notification) => {
          const notificationDate = new Date(notification.message.timestamp).setHours(0, 0, 0, 0);
          
          if (notificationDate === today) {
            acc.Today = acc.Today || [];
            acc.Today.push(notification);
          } else if (notificationDate === yesterday) {
            acc.Yesterday = acc.Yesterday || [];
            acc.Yesterday.push(notification);
          } else {
            const dateString = new Date(notificationDate).toLocaleDateString();
            acc[dateString] = acc[dateString] || [];
            acc[dateString].push(notification);
          }
          
          return acc;
        }, {});
      };

    const groupedNotifications = groupNotificationsByDate(allNotifications);

    const renderNotificationGroup = (date, dateNotifications) => {
        return (
          <div key={date} className="notification-date-group">
            <h2 className="notification-date-header">{date}</h2>
            {Object.entries(groupNotificationsByUser(dateNotifications)).map(([userName, userNotifs]) => 
              renderUserNotifications(userName, userNotifs)
            )}
          </div>
        );
      };

    const groupNotificationsByUser = (notifications) => {
    return notifications.reduce((acc, notification) => {
        const userName = notification?.message?.sender?.name;
        acc[userName] = acc[userName] || [];
        acc[userName].push(notification);
        return acc;
    }, {});
    };

    const renderUserNotifications = (userName, userNotifs) => {
        const isExpanded = expandedUsers[userName] || false;
        const visibleNotifications = isExpanded ? userNotifs : userNotifs.slice(0, 10);
    
        function parseTimeToMinutes(timeStr) {
            const [time, period] = timeStr.split(' ');
            let [hours, minutes] = time.split(':').map(Number);
            if (period === 'PM' && hours < 12) hours += 12;
            if (period === 'AM' && hours === 12) hours = 0;
            return hours * 60 + minutes;
        }
    
        visibleNotifications.sort((a, b) => {
            const minutesA = parseTimeToMinutes(a.message.timestamp);
            const minutesB = parseTimeToMinutes(b.message.timestamp);
            return minutesA - minutesB;
        });
    
        return (
            <div key={userName} className="notification-group">
                <div className={`notification-stack ${isExpanded ? 'expanded' : ''}`}>
                    {visibleNotifications.map((notification, index) => (
                        <div
                            key={index}
                            className="notification-bar"
                            style={{
                                zIndex: userNotifs.length - index,
                                top: isExpanded ? 'auto' : index < 5 ? `${index * 9}px` : '45px',
                                transform: isExpanded ? 'none' : index < 5 ? `scale(${1 - index * 0.02})` : 'scale(0.9)',
                                opacity: isExpanded || index < 5 ? 1 : 0,
                            }}
                            onClick={() => toggleExpand(userName)}
                        >
                            <img src={UserImage} alt="Profile" />
                            <div className='notification-bar-content'>
                                <h2 className="notification-user">{userName}</h2>
                                <p>{notification.message.text}</p>
                            </div>
                            <div className='notification-bar-time'>
                                <p>{formatTimestampTo12Hour(notification.message.timestamp)}</p>
                            </div>
                        </div>
                    ))}
                    {!isExpanded && userNotifs.length > 1 && (
                        <div className="notification-counter">
                            {userNotifs.length}
                        </div>
                    )}
                </div>
            </div>
        );
    };


    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Notification Page</title>
            </Helmet>
          <Sidenav />
          <div className='notifications-container'>
            <div className='notifications-header'>
              <h1>Notifications</h1>
              <div className='notifications-actions'>
              <button className="mark-all-read-button" onClick={handleDeleteSelected}>
                Mark all as read
              </button>
                {/* <SlSettings className="setting-button"/> */}
              </div>
            </div>
            {Object.keys(groupedNotifications).length === 0 ? (
              <div className='notification-no-message'>
                <h2><span>No notifications</span></h2>
              </div>
            ) : (
              Object.entries(groupedNotifications)
                .sort(([dateA], [dateB]) => {
                  if (dateA === 'Today') return -1;
                  if (dateB === 'Today') return 1;
                  if (dateA === 'Yesterday') return -1;
                  if (dateB === 'Yesterday') return 1;
                  return new Date(dateB) - new Date(dateA);
                })
                .map(([date, notifications]) => renderNotificationGroup(date, notifications))
            )}
          </div>
        </React.Fragment>
      );
}

export default Notifications;
