import React, { useState, useEffect, useContext } from "react";
import { Helmet } from 'react-helmet';
import Sidenav2 from "../components/Sidenav.js";
import { API } from '../api-service.js'
import Select from 'react-select';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import { DefinedRange } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import HelpButton from '../components/HelpButton.js';

// Needed to render out the charts
import { Chart as ChartJS, defaults } from 'chart.js/auto';
import { Bar, Doughnut, Line } from 'react-chartjs-2'; 
import './css/Dashboard.css';
import { UserContext } from "../user-context.js";


function Dashboard() {
    // Define state variables
    const { userToken } = useContext(UserContext);
    const [responseRate, setResponseRate] = useState(0);
    const [newClients, setNewClients] = useState(0);
    const [messagesSent, setMessagesSent] = useState(0);
    const [messageSentiment, setMessageSentiment] = useState({});
    const [inquiriesPerMonth, setInquiriesPerMonth] = useState(0);
    const [selectedWabaId, setSelectedWabaId] = useState('');
    const [wabaId, setWabaId] = useState([]);
    const [state, setState] = useState([{
          startDate: new Date(Date.now() - 24*60*60*1000), // 24 hours ago
          endDate: new Date(),
          key: 'selection'
    }]);

    // Load all data
    useEffect(() => {

        const fetchWabaId = async () => {
            try {
                const wabaIdData = await API.getWabaId(userToken);
                setWabaId(wabaIdData.map((item) => ({value: item.waba_id, label: item.display_phone_number})));
            } catch (error) {
                console.error('Error:', error);
            }
        }

        fetchWabaId();

    }, []);

    const displayDataButton = async () => {

        if (selectedWabaId === '') {
            toast.error('Please select a WhatsApp number');
            return;
        }
        const fetchMessageSentiment = async () => {
            try {
                const sentimentData = await API.getMessageSentiment({'token': userToken, 'waba_id': selectedWabaId.value, 'start_date':state[0].startDate, 'end_date':state[0].endDate});
                console.log(sentimentData);
                setMessageSentiment(sentimentData);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const fetchInquiriesCount = async () => {
            try {
                const inquiriesData = await API.getInquiriesCount({'token': userToken, 'waba_id': selectedWabaId.value, 'start_date':state[0].startDate, 'end_date':state[0].endDate});
                // Convert object keys to an array and sort them
                const sortedKeys = Object.keys(inquiriesData).sort();
                // If you need to reconstruct the object in sorted order
                const sortedData = sortedKeys.reduce((acc, key) => {
                    acc[key] = inquiriesData[key];
                    return acc;
                }, {});
                setInquiriesPerMonth(sortedData);
                setMessagesSent(Object.values(inquiriesData).map(value => value.WabaId).reduce((a, b) => a + b, 0));
            } catch (error) {
                console.error('Error:', error);
            }
        }

        const fetchReponseRate = async () => {
            try {
                const responseRateData = await API.getResponseRate({'token': userToken, 'waba_id': selectedWabaId.value, 'start_date':state[0].startDate, 'end_date':state[0].endDate});
                
                setResponseRate(responseRateData['response_rate']);
            } catch (error) {
                console.error('Error:', error);
            }
        }

        const fetchClientCount = async() => {
            try {
                const clientData = await API.getClientCount({'token': userToken, 'waba_id': selectedWabaId.value});
                setNewClients(clientData.length);
            } catch (error) {
                console.error('Error:', error);
            }
        }

        
        fetchClientCount();
        fetchInquiriesCount();
        fetchMessageSentiment();
        fetchReponseRate();
    }

    // Set chart global settings
    defaults.maintainAspectRatio = false;
    defaults.responsive = true;
    defaults.plugins.title.display = true;
    defaults.plugins.title.align = 'start';
    defaults.plugins.title.font.size = 32;
    defaults.plugins.title.color = 'black';

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Dashboard Page</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" data-rh="true"/>
            </Helmet>

            <Sidenav2/>
            <div className="title-dashboard">
                <h1>Dashboard</h1>
            </div>
            <main className='main-dashboard'>
                <div className="top_section_dashboard">
                    <div className="select_date_period">
                        <b>Select WhatsApp Number</b>
                        <Select options={wabaId} value={selectedWabaId} onChange={(selectedOption) => setSelectedWabaId(selectedOption)}/>
                        <DefinedRange onChange={item => setState([item.selection])} ranges={state}/>
                    </div>
                    <button onClick={displayDataButton} className='dashboard-display-btn'>Display data</button>
                </div>

                <div className="bottom_section_dashboard">
                    <div className="dashboard-item">
                        <p>Message Response Rate</p>
                        <h1>{responseRate}</h1>
                    </div>

                    <div className="dashboard-item">
                        <p>Number of new clients</p>
                        <h1>{newClients}</h1>
                    </div>

                    <div className="dashboard-item">
                        <p>Messages Sent</p>
                        <h1>{messagesSent}</h1>
                    </div>

                    <div className="dashboard-item">
                        <p>Message Sentiment</p>
                        <Doughnut
                            data={{
                                labels: ['Positive', 'Negative', 'Neutral'],
                                datasets: [
                                    {
                                        label: 'Sentiment',
                                        data: [messageSentiment['positive'], messageSentiment['negative'], messageSentiment['neutral']],
                                        backgroundColor: [
                                            '#03012C',
                                            '#30638E',
                                            '#14BDEB'
                                        ]
                                    }
                                ]
                            }}
                        />
                    </div>
                    
                    <div className="dashboard-item">
                        <p>Inquiries Per Month</p>
                        <Bar 
                            data={{
                                labels: Object.keys(inquiriesPerMonth),
                                datasets: [
                                    {
                                        label: 'Inquiries',
                                        data:  Object.values(inquiriesPerMonth).map(value => value.ClientUser),
                                        backgroundColor: '#30638E',
                                        borderWidth: 0
                                    }
                                ]
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'right',
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            </main>
            <HelpButton />
        </React.Fragment>
    );
}

export default Dashboard;
