import React, { useState, useEffect, act } from "react";
import { API } from "../../api-service";
import { Bounce, ToastContainer, toast} from 'react-toastify';
import { FaRegThumbsUp } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import '../css/ForgotPassword.css';
import '../css/ResetPassword.css';
import '../css/Activate.css';

// Forgot Password Page ----------------------------------------------
function ForgotPassword() {

    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const sendEmail = async(e) => {
        e.preventDefault();
        if (email === '') {
            toast.error('Email cannot be empty');
            return;
        }

        const exist = await checkEmail(email);
        if (!exist['exists']) {
            toast.error('Email does not exist');
            return
        } else {
            await sendResetEmail(email);
            toast.success('Email sent');
        }
    }

    const sendResetEmail = async (inputEmail) => {
        const response = await API.forgotPassword({'email': inputEmail});
        console.log(response);
        return response
    }

    const checkEmail = async(inputEmail) => {
        const response = await API.checkUserExist({'email': inputEmail, 'username': ''});
        return response;
    }

    return (
        <div className='forgot-password-main'>
            <img src={require("../../assets/header_title.png")} alt="logo" className='forgot-password-logo'/>

            <div className='forgot-password-content'>
                <h1>Forgot Password</h1>
                <p>Enter your email address to reset your password</p>

                <form className='forgot-password-input' onSubmit={sendEmail}>
                    <input type="email" placeholder="Email" value={email} onChange={evt=> setEmail(evt.target.value)}/>
                    <button onClick={sendEmail}>Reset Password</button>
                    <button onClick={() => navigate('/login')}>Back to Sign in</button>
                </form>
                <p>Didn't receive the email? <span onClick={sendEmail}>Click to resend</span></p>
            </div>
            <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
        </div>
    );
}
// -------------------------------------------------------------------


// Reset Password Page -----------------------------------------------
function ResetPassword() {

    const [newPassword, setNewPassword] = useState('');
    const [reNewPassword, setReNewPassword] = useState('');
    const [uid, setUid] = useState('');
    const [token, setToken] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        let pageUrl = window.location.hash.split('/');
        setUid(pageUrl[4]);
        setToken(pageUrl[5]);
    }, []);


    const resetButton = async(e) => {

        e.preventDefault();
        if (newPassword === '' || reNewPassword === '') {
            toast.error('Password cannot be empty');
            return
        } else if (newPassword !== reNewPassword) {
            toast.error('Passwords do not match');
            return
        }

        console.log(uid, token, newPassword, reNewPassword);
        const response = await resetPassword(uid, token, newPassword, reNewPassword);
        console.log(response);
        if ('new_password' in response) {
            if (response['new_password'].includes('This password is too short. It must contain at least 8 characters.')) {
                toast.error('Password is too simple')
            }
            if (response['new_password'].includes('This password is too common.')) {
                toast.error('Password is too common')
            }
            if (response['new_password'].includes('This password is entirely numeric.')) {
                toast.error('Password is entirely numeric')
            }
            return
        } else if ('token' in response) {
            toast.error('Invalid token, please request a new link');
            return
        } else {
            toast.success('Password reset successful');
            navigate('/login');
        }
    }

    const resetPassword = async (uid, token, new_password, re_new_password) => {
        const response = await API.resetPassword({
            'uid': uid,
            'token': token,
            'new_password': new_password,
            're_new_password': re_new_password
        });

        return response;
    }


    return (
        <div>
            <div className='reset-password-main'>
                <img src={require("../../assets/header_title.png")} alt="logo" className='forgot-password-logo'/>

                <div className='reset-password-content'>
                    <h1>Forgot your password?</h1>
                    <p>No worries, reset your password in this page</p>

                    <form className='reset-password-input'>
                        <input type="password" placeholder="New Password" value={newPassword} onChange={evt=> setNewPassword(evt.target.value)}/>
                        <input type="password" placeholder="Retype Password" value={reNewPassword} onChange={evt=> setReNewPassword(evt.target.value)}/>
                        <button onClick={resetButton}>Reset Password</button>
                        <button onClick={() => navigate('/login')}>Back to Sign in</button>
                    </form>
                </div>
                <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            </div>
        </div>
    );
}
// --------------------------------------------------------------------


// Successful Verify Email PAge ----------------------------------------
function Activate() {

    const navigate = useNavigate();

    // Activate account by using the uid and token given in url
    useEffect(() => {
        let pageUrl = window.location.hash.split('/');

        const activateAccount = async() => {
            const response = await API.activateAccount({'uid': pageUrl[2], 'token': pageUrl[3]});
            console.log(response);
            if ('detail' in response) {
                toast.error('Invalid token, please request a new link');
            }
        }

        activateAccount();
    }, []);


    return (
        <main className='activate-main'>
            <div className='activate-header'>
                <img src={require("../../assets/header_title.png")} alt="logo" className='forgot-password-logo'/> 
            </div>

            <div className='activate-content'>
                <h1>Your account has been successfully activated! <FaRegThumbsUp color="#008631" size='2rem'/></h1>
                <p>Now, embark on your journey to financial success and watch the money roll in!</p>

                <button onClick={() => navigate('/login')}>Back to Sign in</button>
            </div>
        </main>
    );
}
// ----------------------------------------------------------------------

export {ResetPassword, Activate, ForgotPassword};