import React, { useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { FaRegQuestionCircle, FaArrowLeft } from 'react-icons/fa';
import './css/HelpButton.css';
import {API} from '../api-service.js';
import { Bounce, ToastContainer, toast} from 'react-toastify';

function HelpButton() {
    const [showModal, setShowModal] = useState(false);
    const [firstName,setFirstName] = useState('');
    const [lastName,setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [emailError, setEmailError] = useState('');
    const [emailMessage, setEmailMessage] = useState('');

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        if (!validateEmail(newEmail)) {
            setEmailError(true);
            setEmailMessage('Invalid email address');
        } else {
            setEmailError(false);
            setEmailMessage('');
        }
    };

    const handleEmailBlur = () => {
        if (email === '') {
            setEmailError(false);
        }
    };

    const inquiriesSubmitBtn = async(e) => {
        e.preventDefault();
        if (emailError){
            toast.error("Please enter a valid email address")
            return;
        }

        if (firstName === '' || lastName === '' || email === '' || message === ''){
            toast.error("Please fill in all the fields")
            return;
        }

        const createInquiries = async() => {
            try {
                const response = await API.addInquiries({
                        'inquiries_id': 'INQUIRIES-' + Date.now(),
                        'first_name': firstName,
                        'last_name': lastName,
                        'email': email,
                        'message': message
                    }); 
    
                console.log(response);
                toast.success("Thank you for your feedback! We have received your message")
            } catch (error) {
                console.log('Error:', error);
                toast.error('Something went wrong');
            }
        }
        createInquiries();
    }

    return (
        <>
            <div className="help-button" onClick={() => setShowModal(true)}>
                <FaRegQuestionCircle />
                <span>HELP</span>
            </div>
            {showModal && (
                <div className="modal-overlay" onClick={() => setShowModal(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-conhead">
                            <div>
                                <IoIosArrowBack className='modal-back-btn' style={{'fontSize': '1.5rem'}} onClick={() => setShowModal(false)}/>
                            </div>
                            <div>
                                <h2>Contact Us</h2>
                                <p>Please write your question and contact information below.</p>
                            </div>
                        </div>

                        <main className={'contact-sales-container modal'}>
                            <div className="contact-form">
                                <div className="contact-name">
                                    <div className="input-container">
                                        <input type="text" id='firstName' placeholder="Enter your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
                                        <label htmlFor='firstName'>First Name</label>
                                    </div>
                                    <div className="input-container">
                                        <input type="text" id='lastName' placeholder="Enter your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                                        <label htmlFor='lastName'>Last Name</label>
                                    </div>
                                </div>
                                <div className="input-container">
                                    <input type="email" id='email' placeholder="user@company.com" value={email} onChange={(e) => handleEmailChange(e)} onBlur={handleEmailBlur}  required />
                                    <label htmlFor='email'>E-mail</label>
                                    {emailError && <span className="error-message">{emailMessage}</span>}
                                </div>
                                <div className="input-container">
                                    <textarea id="message" value={message} placeholder='Enter message' onChange={(e) => setMessage(e.target.value)} required/>
                                    <label htmlFor='message'>Message</label>
                                </div>
                                <button onClick={inquiriesSubmitBtn}>Submit</button>
                            </div>
                        </main>
                        <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
                    </div>
                </div>
            )}
        </>
    );
}

export default HelpButton;
