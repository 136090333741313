import React, { useContext, useState, useEffect } from 'react';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
import { API } from '../api-service';
import { IoIosArrowBack } from "react-icons/io";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { RiFolderAddLine } from 'react-icons/ri';
import { IoSend } from "react-icons/io5";
import { toast } from 'react-toastify';
import { MdDelete } from "react-icons/md";
import './css/DragAndDrop.css';
import { UserContext } from '../user-context';
import 'react-toastify/dist/ReactToastify.css';

const ColorPicker = ({ isOpen, onClose, onColorSelect }) => {
    const colors = ['#2C2B3C', '#848FA5', '#4A6670', '#5F634F', '#2D4739', '#907F9F', '#FFD6BA', '#B76D68'];
  
    if (!isOpen) return null;
  
    return (
        <div className="color-picker">
            {colors.map((color) => (
                <div key={color} className="color-option" style={{ backgroundColor: color }} onClick={() => { onColorSelect(color); onClose(); }} />
            ))}
        </div>
    );
};

function TaskTypeHolder({ taskTypes, taskCounts, selectedTaskType, onTaskTypeClick, onTaskTypesReorder }) {
    const [isAddingTaskType, setIsAddingTaskType] = useState(false);
    const [newTaskType, setNewTaskType] = useState('');

    const handleAddNewTaskType = () => {
        if (newTaskType.trim()) {
            onTaskTypesReorder([...taskTypes, newTaskType.trim()]);
            setNewTaskType('');
            setIsAddingTaskType(false);
        }
    };

    return (
        <div className='taskTypeHolder'>
            {taskTypes.map((type) => (
                <div 
                    key={type} 
                    className={`taskType ${selectedTaskType === type ? 'selected' : ''}`}
                    onClick={() => onTaskTypeClick(type)}
                >
                    <span>{type}</span>
                    {taskCounts[type] > 0 && <span className="count">{taskCounts[type]}</span>}
                </div>
            ))}
            <button className='addTaskType' onClick={() => setIsAddingTaskType(true)}>
                <RiFolderAddLine />
            </button>
            {isAddingTaskType && (
                <div className='addTaskTypeWindow'>
                    <input
                        type='text'
                        placeholder='New task type'
                        value={newTaskType}
                        onChange={(e) => setNewTaskType(e.target.value)}
                    />
                    <button onClick={handleAddNewTaskType}>Add</button>
                    <button onClick={() => setIsAddingTaskType(false)}>Cancel</button>
                </div>
            )}
        </div>
    );
}

function TaskHolder({tasks, removeTask, addTask, deleteTask, taskType, customColors, setCustomColors, folders, addTaskToFolder }) {
    const {userToken} = useContext(UserContext);
    const [addTaskWindow, setAddTaskWindow] = useState(false);
    const [taskTitle, setTaskTitle] = useState('');
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const getCustomColor = (type) => customColors[type] || '#132E32';

    const holderStyle = (type) => ({
        backgroundColor: `${getCustomColor(type)}28`
    });

    const taskStyle = (type) => ({
        backgroundColor: getCustomColor(type)
    });

    const addTaskFunction = () => {
        let title = '';
        if (phoneError) {
            toast.error('Invalid phone number');
            return;
        }
        if (taskTitle === '') {
            toast.error('Task title cannot be empty');
            return;
        }
        if (phoneNumber) {
            title = 'Phone: ' + phoneNumber.replace(/^\+/, '').replace(/\s+/g, '') + '\n\n' + 'Task: ' + taskTitle;
        } else {
            title = 'Task: ' + taskTitle;
        }

        setAddTaskWindow(false); 
        addTask(title, taskType); 
        setTaskTitle('');
    }

    const handleColorSelect = async(color) => {
        try {
            const newCustomColors = {...customColors, [taskType]: color};
            setCustomColors(newCustomColors);
            console.log(userToken, color, taskType)
            const response = await API.editTaskType({'token': userToken, 'task_color': color, 'task_type': taskType})
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    };
    
    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    return (
        <div className='taskHolder'>
            <ColorPicker isOpen={isColorPickerOpen} onClose={() => setIsColorPickerOpen(false)} onColorSelect={handleColorSelect}/>

            <div className='taskItem'>
                {tasks.map((task) => (
                    <Task 
                        key={task.id} 
                        id={task.id} 
                        title={task.task_title} 
                        type={task.task_type} 
                        removeTask={removeTask}
                    />
                ))}
            </div>

            <button className='addTask' style={{ display: addTaskWindow ? 'none': 'block'}} onClick={() => setAddTaskWindow(true)}> 
                + Add New 
            </button>

            <div className='addTaskWindow' style={{display: addTaskWindow ? 'flex' : 'none'}}>
                <div className='addTaskHeader'>
                    <IoIosArrowBack className='taskHeaderBackBtn' style={{fontSize:'1.25rem'}} onClick={() => setAddTaskWindow(false)}/> 
                    <p><b>Add new task</b></p>
                </div>
                <div className="input-container">
                    <input type="tel" id="phoneInput" placeholder="Enter phone number" value={phoneNumber} onChange={handlePhoneChange}/>
                    <label htmlFor="phoneInput">Phone number (optional)</label>
                    {phoneError && <div className="error-message">{phoneError}</div>}
                </div>
                <div className="input-container">
                    <textarea type='text' id='task-title' placeholder='Task title' value={taskTitle} onChange={(evt) => setTaskTitle(evt.target.value)}/>
                    <label htmlFor='task-title'>Task title</label>
                </div>
                <button style={taskStyle(taskType)} onClick={addTaskFunction}>Add task</button>
            </div>
        </div>
    );
}

function Task({id, title, type, removeTask}) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const prefixes = ['Phone:', 'Name:', 'Task:'];
        const regex = new RegExp(`(${prefixes.join('|')})`, 'g');
        const parts = title.split(regex).filter(Boolean);

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const prefix = prefixes.find(p => p === part);
            if (prefix && prefix === 'Phone:' && i + 1 < parts.length) {
                setPhoneNumber(parts[i + 1].trim());
                break;
            }
        }
    }, [title]);

    const renderTitle = (title) => {
        const prefixes = ['Phone:', 'Name:', 'Task:'];
        const regex = new RegExp(`(${prefixes.join('|')})`, 'g');
        const parts = title.split(regex).filter(Boolean);
    
        const rows = [];
        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const prefix = prefixes.find(p => p === part);
            if (prefix) {
                rows.push(
                    <tr key={i} className="task-title-row">
                        <td className={`${prefix.toLowerCase().replace(':', '')}-tag`}>
                            {prefix.slice(0, -1)}
                        </td>
                        <td>{i + 1 < parts.length && parts[i + 1]}</td>
                    </tr>
                );
                i++;
            }
        }
    
        return (
            <table className="task-title-table">
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    };

    const navigateInbox = (phoneNumber) => {
        navigate('/main/inbox/'+phoneNumber+'/');
    }

    return (
        <div className='task'>
            <div className='taskContent'>
                {renderTitle(title)}
            </div>
            <div className='taskButtonContainer'>
                <button className='taskSendButton' onClick={() => navigateInbox(phoneNumber)} style={{'display': phoneNumber ? 'block': 'none'}}><IoSend /></button>
                <button className='taskDeleteButton' onClick={() => removeTask(id)}><IoCheckmarkDoneCircle /></button>
            </div>
        </div>
    )
}

export { TaskHolder, Task };