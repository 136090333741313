// src/utils/loadFacebookSdk.js

export function loadFacebookSdk() {
    return new Promise((resolve, reject) => {
        // Check if the Facebook SDK is already loaded
        if (window.FB) {
            resolve(window.FB);
        } else {
            // Load the Facebook SDK asynchronously
            window.fbAsyncInit = function() {
            window.FB.init({
                appId: '944612860486676',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v20.0'
              });
            resolve(window.FB);
        };

        // Load the SDK script
        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        script.onload = () => {
              resolve(window.FB);
        };
        script.onerror = (error) => {
              reject(error);
        };
        document.body.appendChild(script);
        }
    });
}
  