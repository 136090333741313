import React from "react";
import { Link } from 'react-router-dom';
import Avatar from '../assets/author.jpeg';

const PostAuthor = () => {
    return (
        <Link to='/AuthorPosts' className='post_author'>
            <div className="post_author-avatar">
                <img src={Avatar} alt=""/>
            </div>
            <div className="post_author-details">
                <h5>By: Jason Pong</h5>
                <small>Just Now</small>
            </div>
        </Link>
    )
}

export default PostAuthor;