
import React, { useState, useEffect, useContext } from "react";
import {Helmet} from 'react-helmet';
import { loadFacebookSdk } from '../components/loadFacebookSdk.js';
import Sidenav from "../components/Sidenav.js";
import { UserContext } from "../user-context.js";
import './css/Link.css';
import { useNavigate } from "react-router-dom";
import { API } from "../api-service.js";
import { useWebSocketContext } from './Websocket.js';
import HelpButton from '../components/HelpButton.js';
import { PiLinkBreakBold } from "react-icons/pi";
import { toast } from "react-toastify";
import blue_background from "../assets/blue_background2.jpeg";
import { FaWhatsapp } from "react-icons/fa";




function Link() {
	
	// State variables ------------------------------------------------------------------------------------------------
	const SystemUserToken = "EAANbHtHZC5BQBOZCHluZAO43Kru1a9sEgxZCxEy0iuLi7ZBMoG2Fl8j8xdXjsHFR1Rwx9CeHdjd4qwEEeroywc6TZA51UkQmcDjL91kIfTLHaeZACq77PiTKUbiL2FcaZATKnf0zYww3BmZAOEQgaNBj1aZAvT8VyPXYgNLQK8DtfbnxDLZBFHtMHYhLj9FZBlSx86WhtbZAZBznbLrzi3";
	const { userToken, profileId, setProfileId, setUserId, setContextUserName, setContextFirstName, setRelatedUserProfileId, setContextLastName, setUserType, setImageUrl, setWabaId, wabaId, setWaPhoneNumber, setWabaAccessToken, setWaPhoneNumberId, subscriptionPlan, setSubscriptionPlan, setTranslateFunction, setChatGPTFunction, setNotAllowTaskTypeNotification, setWhatsappNotification } = useContext(UserContext);
  	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const navigate = useNavigate();
	const planWabaLimit = {
		'Free': 1,
		'Small': 2,
		'Medium': 2,
		'Large': 2
	}
	const [activePhoneId, setActivePhoneId] = useState(localStorage.getItem('waba_id') || null);

	const { updateActivePhoneNumber, toggleConnection } = useWebSocketContext();
	const [isLoading, setIsLoading] = useState(true);
	const [phoneExpand, setPhoneExpand] = useState(null);
	// -----------------------------------------------------------------------------------------------------------------


	// A function to fetch user profile
    useEffect(() => {
	
		// Fetch phone numbers
		const fetchWABADetails = async () => {
			try {
				const wabaId = await API.getWabaId(userToken);
				if (wabaId && wabaId.length > 0) {
					console.log(wabaId);
					setPhoneNumbers(wabaId);
					setWabaAccessToken(wabaId.map((item) => item.access_token));
					setWaPhoneNumberId(wabaId.map((item) => item.phone_number_id));
					localStorage.setItem('wabaAccessToken', wabaId.map((item) => item.access_token));
					localStorage.setItem('waPhoneNumberId', wabaId.map((item) => item.phone_number_id));
		
					// Set default active phone number
					if (!activePhoneId) {
						const defaultWaba = wabaId[0];
						setActivePhone(defaultWaba.waba_id, defaultWaba.phone_number_id, defaultWaba.display_phone_number)
					}
				}
			} finally {
				setIsLoading(false);
			}
		};

		const fetchSecondaryWABADetails = async (profileId) => {
			try {
				const wabaId = await API.getSecondaryWabaId({'profile_id': profileId, 'token': userToken});
				if (wabaId && wabaId.length > 0) {
					setPhoneNumbers(wabaId);
					setWabaAccessToken(wabaId.map((item) => item.access_token));
					setWaPhoneNumberId(wabaId.map((item) => item.phone_number_id));
					setRelatedUserProfileId(wabaId[0].profile);
					localStorage.setItem('wabaAccessToken', wabaId.map((item) => item.access_token));
					localStorage.setItem('waPhoneNumberId', wabaId.map((item) => item.phone_number_id));
					localStorage.setItem('relatedUserProfileId', wabaId[0].profile);
				}
			} finally {
				setIsLoading(false);
			}
		}
		const fetchTaskTypes = async () => {
			try {
				const taskTypes = await API.getTaskTypes({'token': userToken});
			} catch (error) {
				console.error('An error occurred while fetching task types:', error);
			}
		}

		// after userToken is set, fetch user profile
		const fetchProfile = async () => {
			try {
				// Fetch user profile
				
				if (userToken) {
					const data = await API.fetchProfile(userToken);
					setProfileId(data['id']);
					setUserId(data['user']['id']);
					setContextUserName(data['user']['username']);
					setContextFirstName(data['user']['first_name']);
					setContextLastName(data['user']['last_name']);
					setImageUrl(data['image']);
					setNotAllowTaskTypeNotification(data['not_allowed_todo_notifications']);
					setWhatsappNotification(data['allow_whatsapp_notifications']);
					let condUserType = '';
					if (data['related_user'] === 'main' || data['related_user'] === null) {
						condUserType = 'main';
						setUserType('main');
						fetchWABADetails();
					} else {
						condUserType = 'secondary';
						setUserType('secondary');
						fetchSecondaryWABADetails(data['id']);
					}
					fetchTaskTypes();
					// Save user profile data in local storage
					localStorage.setItem('profileId', data['id']);
					localStorage.setItem('userId', data['user']['id']);
					localStorage.setItem('userName', data['user']['username']);
					localStorage.setItem('firstName', data['user']['first_name']);
					localStorage.setItem('lastName', data['user']['last_name']);
					localStorage.setItem('userType', condUserType);
					localStorage.setItem('imageUrl', data['image']);
					localStorage.setItem('notAllowTaskTypeNotification', data['not_allowed_todo_notifications']);
					localStorage.setItem('whatsappNotification', data['allow_whatsapp_notifications']);
				} else {
					console.error('User token not found');
				}
			} catch (error) {
				console.error('An error occurred while loading user profile:', error);
			}
		};

		const fetchSubscription = async() => {
            try {
				if (userToken) {
					let data = await API.getSubscription({'token': userToken})
					data = data.sort((a, b) => a.id - b.id);

					console.log(data);

					// Iterate through the sorted data to check for additional plans
					data.forEach((subscription, index) => {
						if (subscription['status'] === 'True') {
							const planType = subscription['plan_type']['plan_type'];
							if (planType === 'Translate') {
								setTranslateFunction(true);
								localStorage.setItem('translateFunction', true);
							} else if (planType === 'ChatGPT') {
								setChatGPTFunction(true);
								localStorage.setItem('chatGPTFunction', true);
							} else {
								setSubscriptionPlan(planType);
								localStorage.setItem('subscriptionPlan', planType);
							}
						}
					});
				}
			} catch (error) {
                console.error(error);
            }
        }
		
		
		fetchProfile();
    	setWabaId(localStorage.getItem('waba_id'));
		updateActivePhoneNumber(wabaId);
		setWaPhoneNumber(localStorage.getItem('waPhoneNumber'));
        fetchSubscription();
		
    }, [userToken]);

	//	-----------------------------------------------------------------------------------------------------------------
	
	
	useEffect(() => {
		if (profileId) {
			toggleConnection(true);
		}
	}, [profileId]);

    // Function to handle launching WhatsApp signup -------------------------------------------------------------------
	const launchWhatsAppSignup = () => {
		if (phoneNumbers.length >= planWabaLimit[subscriptionPlan]) {
			toast.error('You have reached the limit of phone numbers for your subscription plan');
			return;
		}
		try {
			// Load the Facebook SDK
			loadFacebookSdk().then(FB => {
				const APP_SECRET = "5c940635741b759fbe191f18bbacdc47";
				const APP_ID = "944612860486676";
	
				console.log('WHATSAPP EMBEDDED SIGN UP FLOW RAN');
				// Launch Facebook login
				FB.login(function (response) {
					console.log('RESPONSE: ', response);
					if (response.authResponse) {
						console.log('RESPONSE AUTH RESPONSE: ', response.authResponse);
						let accessToken;
						if (response.authResponse.code) {
							// Exchange the code for an access token
							const code = response.authResponse.code;
							console.log('RESPONSE CODE: ', code);
							fetch(`https://graph.facebook.com/v20.0/oauth/access_token?client_id=${APP_ID}&client_secret=${APP_SECRET}&code=${code}`)
								.then(accessTokenResponse => accessTokenResponse.json())
								.then(accessTokenData => {
									accessToken = accessTokenData.access_token;
	
									if (!accessToken) {
										console.error('Failed to retrieve access token');
										return;
									}
	
									console.log('ACCESS TOKEN: ', accessToken);
									// Wrap the async logic in a separate function
									const handleResponse = (accessToken) => {
										fetch(`https://graph.facebook.com/v20.0/debug_token?input_token=${accessToken}`, {
											headers: {
												'Authorization': `Bearer ${SystemUserToken}`,
											},
										})
										.then(response => response.json())
										.then(data => {
											console.log('RESPONSE DATA: ', data);
											console.log('GRANULAR SCOPES: ', data.data.granular_scopes);
											console.log('WHATSAPP BUSINESS MANAGEMENT SCOPE: ', data.data.granular_scopes.find(scope => scope.scope === "whatsapp_business_management"));
											const whatsappBusinessManagementScope = data.data.granular_scopes.find(scope => scope.scope === "whatsapp_business_management");
											if (whatsappBusinessManagementScope && whatsappBusinessManagementScope.target_ids.length > 0) {
												const firstTargetId = whatsappBusinessManagementScope.target_ids[0];
												console.log('FIRST TARGET ID:', firstTargetId);
												API.getPhoneNumbers(firstTargetId, SystemUserToken)  // Get the phone number details first
													.then(phoneNumbers => {  // Log the phone number details
														const phoneNumberData = phoneNumbers.data[0];
														console.log("Phone Number Data: ", phoneNumberData);  // Extract the first phone number data
														return API.wabaFunction({
															waba_id: firstTargetId,
															access_token: accessToken,
															verified_name: phoneNumberData.verified_name,
															code_verification_status: phoneNumberData.code_verification_status,
															display_phone_number: phoneNumberData.display_phone_number,
															id: phoneNumberData.id
														}, userToken);  // Pass the phone number data to wabaFunction
													})
													.then(response => {
														console.log(response);  // Log the response from wabaFunction
														return API.getPhoneNumbers(firstTargetId, SystemUserToken);  // Fetch phone numbers again to get the latest data
													})
													.then(async phoneNumbers => {
														console.log("Registering user with phone numbers: ", phoneNumbers.data, SystemUserToken);

														// Use Promise.all to register all phone numbers concurrently
														try {
															const results = await Promise.all(phoneNumbers.data.map(phoneNumberData => API.registerPhoneNumber(firstTargetId, phoneNumberData.id, SystemUserToken)
															));
															console.log("All phone numbers registered:", results);
															return results;
														} catch (error) {
															console.error("Error registering phone numbers:", error);
															throw error; // Rethrow the error to be caught by the caller
														}
													})
													.catch(error => console.error(error));
											}
										})
										.catch(console.error);
									};
	
									// THIS IS THE FUCKING EMBEDDED SIGN UP FLOW TODO: DONT'T DELETE
									handleResponse(accessToken).catch(console.error);
								})
								.catch(console.error);
						}
					} else {
						console.log('ERROR RESPONSE: ', response);
						console.log('User cancelled login or did not fully authorize.');
					}
				}, {
					config_id: '487021173697356',
					response_type: 'code',
					override_default_response_type: true,
					extras: {
						"feature": "whatsapp_embedded_signup",
						"version": 2,
					}
				});
			}).catch(error => {
				console.error('An error occurred while loading or using the Facebook SDK:', error);
			});
		} catch (error) {
			console.error('An unexpected error occurred:', error);
		}
	};

	// -----------------------------------------------------------------------------------------------------------------

	//set active phone number id
	const setActivePhone = async (waba_id, waPhoneNumberId, waPhoneNumber)  => {
		setWabaId(waba_id);
		localStorage.setItem('waba_id', waba_id);
		setWaPhoneNumberId(waPhoneNumberId);
		localStorage.setItem('waPhoneNumberId', waPhoneNumberId);
		setWaPhoneNumber(waPhoneNumber);
		localStorage.setItem('waPhoneNumber', waPhoneNumber);
		updateActivePhoneNumber(waba_id);
		setActivePhoneId(waba_id); 
	};

	const downloadQrPng = async (link) => {
		try {
		  // Fetch the image data as a blob
		  const response = await fetch(link);
		  const blob = await response.blob();
	
		  // Create a blob URL for the image
		  const blobUrl = window.URL.createObjectURL(blob);
	
		  // Create a temporary anchor element
		  const anchor = document.createElement("a");
		  anchor.href = blobUrl;
		  anchor.download = "qr_code.png"; // Set the desired file name
	
		  // Append to the document, trigger click, and remove it
		  document.body.appendChild(anchor);
		  anchor.click();
		  document.body.removeChild(anchor);
	
		  // Revoke the blob URL to free memory
		  window.URL.revokeObjectURL(blobUrl);
		} catch (error) {
		  console.error("Failed to download image:", error);
		}
	}

	
    // If user hasn't log in
    if (userToken === null) {
        navigate('/login');
    }

    return (
        <React.Fragment>

            <Helmet>
                <title>StreamHost | Link Page</title>
            </Helmet>

            <Sidenav/>
            <main className ='main-link'>
                <div className="title">
					<div className='title-main'>
						<h1>Linked Phone</h1>
					</div>
                </div>
                <div className="linked-phone">
					<div className="connect-header">
						<div className="connect-subheader">
						{/* This button connects to Whatsapp API embedded sign in */}
						<button id = "add-phone-btn" onClick={launchWhatsAppSignup}><FaWhatsapp />Add whatsapp account</button>
						</div>
					</div>
					<div className="connected-phones">
					{isLoading ? (
						<div className="loader-container">
							<div className="loader"></div>
						</div>
					) : (
						<ul className="phone-list">
						{phoneNumbers.length > 0 ? (
							<div className="connected-phones">
								<ul className="phone-list">
								{phoneNumbers.map((phoneNumber) => (
									<li key={phoneNumber.waba_id}>
										<div className={`phone-item ${phoneNumber.waba_id === activePhoneId ? 'active' : ''}`} onClick={() => setPhoneExpand(phoneExpand === phoneNumber.waba_id ? null : phoneNumber.waba_id)}
											style={{ backgroundImage: phoneNumber.waba_id === activePhoneId ? `url(${blue_background})` : 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}>
											<div className="phone-item-text">
												<span className="phone-no">{phoneNumber.display_phone_number}</span>
												<span className="owner-text">{phoneNumber.verified_name}</span>
											</div>
											<div className='active-button-link'>
												<button className="edit-btn" onClick={() => setActivePhone(phoneNumber.waba_id, phoneNumber.phone_number_id, phoneNumber.display_phone_number)}>
													{phoneNumber.waba_id === activePhoneId ? 'Active now' : 'Activate'}
												</button>
											</div>
										</div>

										<div className={`phone-link ${phoneExpand === activePhoneId ? 'expanded' : ''}`}>
											<span>
												{`https://streamhost.app/#/sub/login/${phoneNumber.direct_link}/${phoneNumber.waba_id}/`}
											</span>
											<button onClick={() => downloadQrPng(phoneNumber.qr_code)}>Download QR Code</button>
										</div>
									</li>
								))}
							</ul>
						</div>
						) : (
							<div className='link-no-account'>
								<h2><PiLinkBreakBold /><span>No phone numbers added yet</span></h2>
							</div>
						)}
						</ul>
					)}
					</div>

                </div>
				<HelpButton />
            </main>
        </React.Fragment>
    );
}


export default Link;