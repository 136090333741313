import Thumbnail1 from '../assets/blogthumb.jpg';
import Thumbnail2 from '../assets/blogthumb2.png';
import Thumbnail3 from '../assets/transparent_logo.png';

export const DUMMY_POSTS = [
    {
        id:'1',
        thumbnail: Thumbnail1,
        category: 'education',
        title: 'Unlocking the Power of WhatsApp Business API: A Comprehensive Guide for Business Growth',
        desc:'This guide aims to explore the WhatsApp Business API in detail, including its features, benefits, pricing, and how it can be effectively implemented across various business functions to drive growth.',
        authorID:2
    },
    {
        id:'2',
        thumbnail: Thumbnail2,
        category: 'education',
        title: 'Common Errors in the Embedded WhatsApp Signup Flow: A Troubleshooting Guide',
        desc:'This guide covers some of the most frequent issues faced during the WhatsApp Business API setup process, providing insights on how to resolve them and ensure a smooth integration.',
        authorID:1
    },
]