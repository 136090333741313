import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { API } from "../api-service";
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

import './css/Sublogin.css';

function SubLogin() {
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userToken, setUserToken] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [massFlowOption, setMassFlowOption] = useState([]);
    const [unitOption, setUnitOption] = useState({});
    const [selectedChatflow, setSelectedChatflow] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('');
    const { direct_link, waba_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const setUpLoginInfo = async () => {
            try {
                const response = await API.getDirectLinkUser({'direct_link': direct_link});
                setUserId(response['user']['id']);
                const token = await API.getToken({'user_id': response['user']['id']});
                setUserToken(token['token']);
                const chatFlowList = await API.getChatFlowList(token['token']);
                const filteredData = chatFlowList.filter(chatflow => chatflow.type === 'massflow' && chatflow.error_status === true).map(chatflow => ({value: chatflow.id, label: chatflow.chatflow_name}));
                setMassFlowOption(filteredData);

                const mappedUnit = {};
                const oriUnitList = {};

                await Promise.all(filteredData.map(async (chatflow) => {
                    const response = await API.getNodes({ 'token': token['token'], 'id': chatflow.value });
                    const unitNodes = response?.filter(node => node.node_type === 'unitNode');
                    
                    const unitNodeComplete = unitNodes.reduce((acc, node) => {
                        const nodeTextArray = JSON.parse(node.text);
                        return [...acc, ...nodeTextArray];
                    }, []);
                    
                    oriUnitList[chatflow.label] = unitNodeComplete;
                    mappedUnit[chatflow.label] = unitNodeComplete.map(node => ({ value: node, label: node }));
                }));

                setUnitOption(mappedUnit);
            } catch (error) {
                console.error(error);
            }
          };

        setUpLoginInfo();
    }, [direct_link]);


    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        // Get or create client user
        let client_user = await API.getClientUser({'token': userToken, 'phone_number': phoneNumber.replace(/\s+/g, '').replace('+', '')});
        if ('error' in client_user) {
            client_user = await API.addClientUser({
                'token': userToken,
                'phone_number': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                'name': userName,
                'checkin_datetime': null,
                'checkout_datetime': null,
                'unit_no': null,
                'wabaId': waba_id,  
                'checkin_status': true,
                'checkout_status': true,
                'chatflow_id': null,
            })
        }

        navigate('/sub/streamhost-inbox/'+ userId + '/' + phoneNumber + '/' + waba_id + '/');
    };
    

    return (
        <div className='sub-login-main'>
            <div className="sub-login-container">
                <div className='sub-login-header'>
                    <img src={require("../assets/transparent_logo.png")} style={{ height: '5rem'}} />
                    <h2>Login</h2>
                </div>
                <form className="sub-login-form" onSubmit={handleSubmit}>
                    <div className="input-container">
                        <input type="text" id="userName" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Enter your phone number" required />
                        <label htmlFor="userName">Username</label>
                    </div>
                    <div className="input-container">
                        <input type="tel" id="phone" value={phoneNumber} onChange={(e) => handlePhoneChange(e)} placeholder="Enter your phone number" required />
                        <label htmlFor="phone">Phone Number</label>
                        {phoneError && <div className="error-message">{phoneError}</div>}
                    </div>

                    <div className="form-group">
                        <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={selectedChatflow} onChange={(selectedOption) => setSelectedChatflow(selectedOption)} />
                    </div>


                    <div className="form-group">
                        <Select className='modal-select' placeholder={'Select unit'} options={unitOption[selectedChatflow.label]} value={selectedUnit} onChange={(selectedOption) => setSelectedUnit(selectedOption)} />
                    </div>

                    <button type="submit" className="sublogin-button">Login</button>
                </form>
            </div>
        </div>
    );
}

export { SubLogin };