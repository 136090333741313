// src/serviceWorkerRegistration.js

const isLocalhost = Boolean(
        window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
  
    export function register() {
        if ('serviceWorker' in navigator) {
              window.addEventListener('load', () => {
                  const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
            
                  if (isLocalhost) {
                      // This is running on localhost. Check if a service worker still exists or not.
                      checkValidServiceWorker(swUrl);
                  } else {
                      // Register the service worker
                      registerValidSW(swUrl);
                }
            });
        }
    }
  
    function registerValidSW(swUrl) {
        navigator.serviceWorker
            .register(swUrl)
            .then((registration) => {
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    if (installingWorker == null) {
                        return;
                    }
                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === 'installed') {
                            if (navigator.serviceWorker.controller) {
                                console.log('New content is available; please refresh.');
                            } else {
                                console.log('Content is cached for offline use.');
                            }
                        }
                    };
                };
            })
            .catch((error) => {
                console.error('Error during service worker registration:', error);
            });
    }
  
    function checkValidServiceWorker(swUrl) {
        fetch(swUrl)
            .then((response) => {
                const contentType = response.headers.get('content-type');
                  if (
                      response.status === 404 ||
                      (contentType != null && contentType.indexOf('javascript') === -1)
                  ) {
                      // No service worker found. Load a fallback page.
                      navigator.serviceWorker.ready.then((registration) => {
                            registration.unregister().then(() => {
                                window.location.reload();
                            });
                      });
                  } else {
                        // Service worker found. Proceed with the registration.
                        registerValidSW(swUrl);
                  }
            })
            .catch(() => {
                console.log(
                    'No internet connection found. App is running in offline mode.'
                );
          });
    }
    
    export function unregister() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                registration.unregister();
            });
        }
    }
  