import React, { useState,useContext } from 'react';
import { MainHeader, MainFooter } from "../components/Layout";
import './css/ContactSales.css';
import { Helmet } from 'react-helmet';
import {API} from '../api-service.js';
import { Bounce, ToastContainer, toast} from 'react-toastify';

function ContactSales({ isModal }) {
    const [firstName,setFirstName] = useState('');
    const [lastName,setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [companyName, setCompanyName] = useState('');

    const contactBtn = async(e) => {
        e.preventDefault();

        if (!firstName || !lastName || !email || !message || !companySize || !companyName) {
            toast.error('Please fill in all the fields');
            return;
        }

        const createInquiries = async() => {
            try {
                const response = await API.addInquiries({
                    'inquiries_id': 'INQUIRIES-' + Date.now(),
                    'first_name': firstName,
                    'last_name': lastName,
                    'email': email,
                    'message': message
                });
                toast.success('Inquiries sent successfully')
            } catch (error) {
                console.error(error);
                toast.error('Something went wrong !');
            }
        }
        createInquiries();
    }

    return (
        <div>
            {!isModal && (
                <Helmet>
                    <title>Contact Sales | StreamHost</title>
                </Helmet>
            )}

            {!isModal && <MainHeader />}
            <main className={`contact-sales-container ${isModal ? 'modal' : ''}`}>
                <div className="contact-info">
                    <h1>Contact our Sales team</h1>
                    <p>Let's Explore how StreamHost can work for you</p>
                    <div className="feature-boxes">
                        <div className="feature-box">
                            WhatsApp Tool designed specifically for Short Rental Hosts by StreamHost the Official Meta Business Partner
                        </div>
                        <div className="feature-box">
                            Features Included: 24/7 Chatbot, Message Translation, Schedule Message for Check In & Check Out, To Do List to keep track of tasks
                        </div>
                        <div className="feature-box">
                            Increase Conversions, Increase Revenue, Increase Customer Satisfaction, Reduce Costs and Expenses
                        </div>
                    </div>
                </div>
                <div className="contact-form">
                    <form className="contact">
                        <div className="input-group-row">
                            <div class="input-container">
                                <input type="text" id="first_name" placeholder="Enter your First Name" value={firstName} onChange={(e)=>setFirstName(e.target.value)} required/>
                                <label for="first_name">First Name</label>
                            </div>
                            <div class="input-container">
                                <input type="text" id="last_name" placeholder="Enter your Last Name" value={lastName} onChange={(e)=>setLastName(e.target.value)} required/>
                                <label for="last_name">Last Name</label>
                            </div>
                        </div>
                        <div class="input-container">
                            <input type="email" id="email" placeholder="Work email" value={email} onChange={(e)=>setEmail(e.target.value)} required/>
                            <label for="email">Work Email</label>
                        </div>
                        <div class="input-container">
                            <input type="text" id="company" placeholder="Company name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required/>
                            <label for="company">Company name</label>
                        </div>
                        <div className="input-container">
                            <select name="companySize" placeholder='Select company size' onChange={(e) => setCompanySize(e.target.value)} required>
                                <option value="">Please select company size</option>
                                <option value="1-10">1-10</option>
                                <option value="11-50">11-50</option>
                                <option value="51-200">51-200</option>
                                <option value="201-500">201-500</option>
                                <option value="501-1000">501-1000</option>
                                <option value="1001+">1001+</option>
                            </select>
                        </div>
                        <div className="input-container">
                            <textarea name="message" id='message' value={message} onChange={(e)=>setMessage(e.target.value)} placeholder='Enter message' required />
                            <label htmlFor='message'>Enter message</label>
                        </div>
                        <button type="submit" className="submit-button" onClick={contactBtn}>Submit</button>
                    </form>
                </div>
                <ToastContainer style={{zIndex: '100000001'}} position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} /> 
            </main>
            {!isModal && <MainFooter />}
        </div>
    );
}

export { ContactSales };


