import React, { createContext, useState, useEffect, useCallback } from 'react';

// 1. Allow login token to be passed to any other page for fetching user specific data purposes.

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [userToken, setUserToken] = useState(null);

    // Profile Data
    const [profileId, setProfileId] = useState('');
    const [userId, setUserId] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [userName, setContextUserName] = useState('');
    const [firstName, setContextFirstName] = useState('');
    const [lastName, setContextLastName] = useState('');
    const [email, setContextEmail] = useState('');
    const [password, setContextPassword] = useState('');
    const [userType, setUserType] = useState('');
    const [wabaId, setWabaId] = useState(null);
    const [waPhoneNumber, setWaPhoneNumber] = useState(null);
    const [wabaAccessToken, setWabaAccessToken] = useState([]);
    const [waPhoneNumberId, setWaPhoneNumberId] = useState([]);
    const [relatedUserProfileId, setRelatedUserProfileId] = useState([]);
    const [contactsCount, setContactsCount] = useState(0);
    const [subscriptionPlan, setSubscriptionPlan] = useState('');
    const [translateFunction, setTranslateFunction] = useState(true);
    const [chatGPTFunction, setChatGPTFunction] = useState(true);
    const [notAllowTaskTypeNotification, setNotAllowTaskTypeNotification] = useState([]);
    const [whatsappNotification, setWhatsappNotification] = useState(false);
    const [deviceId, setDeviceId] = useState('');
    const [taskCounts, setTaskCounts] = useState({});
    const hasUndoneTasks = Object.values(taskCounts).some(count => count > 0); 

    const updateTaskCounts = (counts) => {
        setTaskCounts(counts); 
    };
    const resetUserData = () => {
        // Store the deviceId
        const deviceId = localStorage.getItem('deviceId');
    
        // Clear all items in localStorage
        localStorage.clear();
    
        // Restore the deviceId
        if (deviceId) {
            localStorage.setItem('deviceId', deviceId);
        }
    
        // Reset all state variables
        setUserToken(null);
        setProfileId('');
        setUserId('');
        setImageUrl('');
        setContextUserName('');
        setContextFirstName('');
        setContextLastName('');
        setContextEmail('');
        setContextPassword('');
        setUserType('');
        setWabaId(null);
        setWaPhoneNumber(null);
        setWabaAccessToken([]);
        setWaPhoneNumberId([]);
        setRelatedUserProfileId([]);
        setNotAllowTaskTypeNotification([]);
        setWhatsappNotification(false);
        setSubscriptionPlan('');
        setTranslateFunction(true);  
        setChatGPTFunction(true);      
        setUserToken(null);
        // Don't reset deviceId in the state
        // setDeviceId('');
    };

    // Search for local storage for data. (Prevent reloading of data on refresh)
    useEffect(() => {
        if(localStorage.getItem('userToken')) {
            setUserToken(localStorage.getItem('userToken'));
            // Retrieve user profile data from local storage
            setProfileId(localStorage.getItem('profileId'));
            setUserId(localStorage.getItem('userId'));
            setContextUserName(localStorage.getItem('userName'));
            setUserType(localStorage.getItem('userType'))
            setContextFirstName(localStorage.getItem('firstName'));
            setContextLastName(localStorage.getItem('lastName'));
            setWabaId(localStorage.getItem('waba_id'));
            setWaPhoneNumber(localStorage.getItem('waPhoneNumber'));
            setWabaAccessToken(localStorage.getItem('wabaAccessToken'));  
            setWaPhoneNumberId(localStorage.getItem('waPhoneNumberId'));
            setRelatedUserProfileId(localStorage.getItem('relatedUserProfileId'));
            setSubscriptionPlan(localStorage.getItem('subscriptionPlan'));
            setTranslateFunction(true);
            setChatGPTFunction(true);
            setNotAllowTaskTypeNotification(localStorage.getItem('notAllowTaskTypeNotification'));
            setWhatsappNotification(localStorage.getItem('whatsappNotification'));
            setDeviceId(localStorage.getItem('deviceId'));
        } else {
            console.log("No user token found")
        }
    }, []) 

    // Provide context into children (In this case, it will be <App/> in index.js)
    return (
        <UserContext.Provider value={{ 
            SystemUserToken: "EAANbHtHZC5BQBOZCHluZAO43Kru1a9sEgxZCxEy0iuLi7ZBMoG2Fl8j8xdXjsHFR1Rwx9CeHdjd4qwEEeroywc6TZA51UkQmcDjL91kIfTLHaeZACq77PiTKUbiL2FcaZATKnf0zYww3BmZAOEQgaNBj1aZAvT8VyPXYgNLQK8DtfbnxDLZBFHtMHYhLj9FZBlSx86WhtbZAZBznbLrzi3",
            userToken, setUserToken,
            profileId, setProfileId,
            userId, setUserId,
            imageUrl, setImageUrl,
            userName, setContextUserName,
            firstName, setContextFirstName,
            lastName, setContextLastName,
            userType, setUserType,
            email, setContextEmail,
            password, setContextPassword,
            wabaId, setWabaId,
            waPhoneNumber, setWaPhoneNumber,
            wabaAccessToken, setWabaAccessToken,
            waPhoneNumberId, setWaPhoneNumberId,
            relatedUserProfileId, setRelatedUserProfileId,
            notAllowTaskTypeNotification, setNotAllowTaskTypeNotification,
            whatsappNotification, setWhatsappNotification,
            contactsCount, setContactsCount,
            subscriptionPlan, setSubscriptionPlan,
            translateFunction, setTranslateFunction,
            chatGPTFunction, setChatGPTFunction,
            deviceId, setDeviceId,
            resetUserData,
            taskCounts,
            updateTaskCounts,
            hasUndoneTasks,
        }}>
            { children }
        </UserContext.Provider>

    )
}

export { UserContext, UserProvider };