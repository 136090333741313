import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { MainHeader, MainFooter } from '../components/Layout.js';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

function togglePlan(plan, setSmallPrice, setMediumPrice, setLargePrice) {
    if (plan === 'yearly') {
        setSmallPrice('RM339/month');
        setMediumPrice('RM679/month');
        setLargePrice('RM1,189/month');
    } else if (plan === 'monthly') {
        setSmallPrice('RM399/month');
        setMediumPrice('RM799/month');
        setLargePrice('RM1,399/month');
    }
}

function Pricing() {
    const [isYearly, setIsYearly] = useState(false);
    const [smallPrice, setSmallPrice] = useState('RM399/month');
    const [mediumPrice, setMediumPrice] = useState('RM799/month');
    const [largePrice, setLargePrice] = useState('RM1,399/month');
    const [addonsVisible, setAddonsVisible] = useState(false);

    useEffect(() => {
        togglePlan(isYearly ? 'yearly' : 'monthly', setSmallPrice, setMediumPrice, setLargePrice);
    }, [isYearly]);

    const toggleAddons = () => {
        setAddonsVisible(!addonsVisible);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Pricing and Plans</title>
            </Helmet>

            <MainHeader />
            <main className='pricing-main'>
                <section className="pricing-header">
                    <h1>CHOOSE YOUR PLAN</h1>
                    <p>Whether it's for personal use, or business use. We have a plan for you</p>
                    {/* <div className="toggle-container">
                        <button className={`toggle-button ${isYearly ? 'active' : ''}`} onClick={() => setIsYearly(true)}>Yearly</button>
                        <button className={`toggle-button ${!isYearly ? 'active' : ''}`} onClick={() => setIsYearly(false)}>Monthly</button>
                        <div className="toggle-slider" style={{ transform: isYearly ? 'translateX(0)' : 'translateX(100%)' }}></div>
                    </div> */}
                </section>

                <section className="price-section">
                    <div className="price-card" id="startup-card">
                        <div className="card-header startup-header">
                            <div className="header-background"></div>
                            <div className="header-text">
                                <h2>Start Up</h2>
                                <h3>Free</h3>
                                <p>This package is for start-ups or solopreneurs looking for free tool with essential features to build a chatbot and handle check-in or check-out messages with WhatsApp.</p>
                                <Link to='/login'><button className="start-button2">Get Started</button></Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <h4>Features you'll love:</h4>
                            <p><i className="ri-contacts-book-2-line"></i> 100 customer contacts</p>
                            <p><i className="ri-user-line"></i> 1 users</p>
                            <p><i className="ri-whatsapp-line"></i> 1 WhatsApp Business Account </p>
                        </div>
                    </div>

                    <div className="price-card" id="small-card">
                        <div className="card-header small-header">
                            <div className="header-background"></div>
                            <div className="header-text">
                                <h2>Small</h2>
                                <h3 id="smallPrice">{smallPrice}</h3>
                                <p>This package is recommended for small businesses which have 10 - 30 units who are looking for a WhatsApp solution that helps accelerate sales growth and provide quality customer support to customers.</p>
                                <Link to='/login'><button className="start-button2">Get Started</button></Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <p><i className="ri-contacts-book-2-line"></i> 5000 customer contacts</p>
                            <p><i className="ri-user-line"></i> 2 users</p>
                            <p><i className="ri-whatsapp-line"></i> 2 WhatsApp Business Account</p>
                            <p><i className="ri-function-line"></i> Free Onboarding Support</p>
                            <p><i className="ri-wechat-line"></i> Free Trial for one month</p>
                        </div>
                    </div>

                    <div className="price-card" id="medium-card">
                        <div className="card-header medium-header">
                            <div className="header-background"></div>
                            <div className="header-text">
                                <div className="title-container">
                                    <h2>Medium</h2>
                                    <div className="most-popular-badge">Most Popular</div>
                                </div>
                                <h3 id="mediumPrice">{mediumPrice}</h3>
                                <p>This package is recommended for medium businesses which have 30 - 100 units who are looking to accelerate their business operations and provide higher quality customer support to customers.</p>
                                <Link to='/login'><button className="start-button">Get Started</button></Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <p><i className="ri-contacts-book-2-line"></i> 10,000 customer contacts</p>
                            <p><i className="ri-user-line"></i> 5 users</p>
                            <p><i className="ri-whatsapp-line"></i> 2 WhatsApp Business Account</p>
                            <p><i className="ri-broadcast-line"></i> WhatsApp chat and email for customer support</p>
                            <p><i className="ri-function-line"></i> Free Onboarding Support</p>
                            <p><i className="ri-wechat-line"></i> Free Trial for one month</p>
                        </div>
                    </div>

                    <div className="price-card" id="large-card">
                        <div className="card-header large-header">
                            <div className="header-background"></div>
                            <div className="header-text">
                                <h2>Large</h2>
                                <h3 id="largePrice">{largePrice}</h3>
                                <p>This package is recommended for large business which have more than 100 units who are looking to provide better quality customer support to customers</p>
                                <Link to='/login'><button className="start-button2">Get Started</button></Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <p><i className="ri-contacts-book-2-line"></i> 20,000 customer contacts</p>
                            <p><i className="ri-user-line"></i> 10 users</p>
                            <p><i className="ri-whatsapp-line"></i> 2 WhatsApp Business Account</p>
                            <p><i className="ri-broadcast-line"></i> WhatsApp chat and email for customer support</p>
                            <p><i className="ri-function-line"></i> Free Onboarding Support</p>
                            <p><i className="ri-wechat-line"></i> Free Trial for one month</p>
                        </div>
                    </div>
                </section>

                <div className="addons-container">
                    <h2 className="addons-title" onClick={toggleAddons}>
                        Add-ons
                        <span className="arrow-icon">
                            {addonsVisible ? <FaChevronUp /> : <FaChevronDown />}
                        </span>
                    </h2>
                    <div className={`addons-content ${addonsVisible ? 'visible' : ''}`}>
                        <table className="addons-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Additional user</th>
                                    <td>RM 50/month</td>
                                </tr>
                                <tr>
                                    <th>Additional 5,000 customer contacts</th>
                                    <td>RM 200</td>
                                </tr>
                                <tr>
                                    <th>In-chat translation function</th>
                                    <td>From RM 75/month</td>
                                </tr>
                                <tr>
                                    <th>ChatGPT function</th>
                                    <td>From RM 500/month</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="exclusive-savings">
                    <h1>UNLOCK EXCLUSIVE SAVINGS</h1>
                    <p>Unlock exclusive savings by contacting our sales team today. Our experts are ready to provide you with personalized solutions tailored to your specific needs. Don't miss out on this opportunity to maximize your business growth and profitability.</p>
                    <Link to='/ContactSales'><button>CONTACT SALES</button></Link>
                </div>
            </main>
            <MainFooter />
        </React.Fragment>
    );
}

export default Pricing;
