import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get URL parameters
import { DUMMY_POSTS } from '../pages/data.js';
import PostItem from '../pages/PostItem.js';
import { MainHeader, MainFooter } from "../components/Layout";

const CategoryPosts = () => {
    const { category } = useParams(); // Get the category from the URL parameters
    const [posts, setPosts] = useState(DUMMY_POSTS);
    const [filteredPosts, setFilteredPosts] = useState([]);

    useEffect(() => {
        // Filter posts based on the category from URL
        if (category) {
            const filtered = posts.filter(post => post.category === category);
            setFilteredPosts(filtered);
        } else {
            setFilteredPosts(posts); // If no category is specified, show all posts
        }
    }, [category, posts]); // Dependency array to run the effect when category or posts change

    return (
        <section>
            <MainHeader />
            {filteredPosts.length > 0 ? (
                <div className="container posts_container">
                    {filteredPosts.map(({id, thumbnail, category, title, desc, authorID}) => (
                        <PostItem key={id} postID={id} thumbnail={thumbnail} category={category} title={title} description={desc} authorID={authorID} />
                    ))}
                </div>
            ) : (
                <h2 className='center'>No posts found</h2>
            )}
            <MainFooter />
        </section>
    );
}

export default CategoryPosts;
