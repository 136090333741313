import React, { createContext, useContext, useState, useRef, useEffect, useCallback } from 'react';
import { UserContext } from "../user-context.js";
import addNotification from 'react-push-notification';
import { API } from '../api-service';
import { v4 as uuidv4 } from 'uuid';
const WebSocketContext = createContext();

export const useWebSocketContext = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    const { profileId, relatedUserProfileId, notAllowTaskTypeNotification, whatsappNotification } = useContext(UserContext);
    const [activePhoneNumber, setActivePhoneNumber] = useState(null);
    const socketRef = useRef(null);
    const socketRef2 = useRef(null);
    const [notificationPermission, setNotificationPermission] = useState('default');
    const [isConnected, setIsConnected] = useState(true);
    const [notificationToggle, setNotificationToggle] = useState(false);
    const [pushSubscription, setPushSubscription] = useState(null);
    
    //Request permission for notifications
    async function setupNotifications() {
        if ('Notification' in window && 'serviceWorker' in navigator ) {
            try {
                const permission = await Notification.requestPermission();
                setNotificationPermission(permission);
    
                if (permission === 'granted') {
                    const registration = await navigator.serviceWorker.ready;
                    
                    let subscription = await registration.pushManager.getSubscription();
                    
                    let deviceId = localStorage.getItem('deviceId');
                    if (!deviceId) {
                        deviceId = uuidv4();
                        localStorage.setItem('deviceId', deviceId);
                    }


                    const response = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/vapidPublicKey/`);
                    const { publicKey } = await response.json();

                    subscription = await registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(publicKey)
                    });
                    console.log("subscription: ", subscription);
                    console.log("deviceId: ", deviceId);
                    const response2 = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/subscribe/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            'subscription': subscription.toJSON(), // Convert subscription to JSON
                            'profileId': localStorage.getItem('profileId'),
                            'isSubscribed': true,
                            'deviceId': deviceId
                        })
                    });
                    if (!response2.ok) {
                        const errorData = await response2.json();
                        throw new Error(errorData.error || 'Failed to subscribe to push notifications');
                    }
    
                    setPushSubscription(subscription);
                    // Store subscription in local storage
                    localStorage.setItem('pushSubscription', JSON.stringify(subscription.toJSON()));
                }
            } catch (error) {
                console.error("Error in setupNotifications:", error);
            }
        } else {
            console.log("Notifications or Service Workers not supported");
        }
    }
    useEffect(() => {     

        setupNotifications();
    }, []);


    useEffect(() => {
        if ('Notification' in window) {
            Notification.requestPermission().then(permission => {
                setNotificationPermission(permission);
            });
        }
    }, []);
    

    // Changes socket connection based on change in activePhoneNumber
    useEffect(() => {
        if (relatedUserProfileId && relatedUserProfileId.length > 0) {
            console.log(relatedUserProfileId);
            globalSocketCloseHandler(relatedUserProfileId);
            
        } else {
            globalSocketCloseHandler(profileId);
        }

    }, [relatedUserProfileId]);

    
    // Changes socket connection based on change in notificationToggle
    useEffect(() => {
        if (isConnected === false) {
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
                console.log("globalSocket connection closed");
            }
            if (socketRef2.current) {
                socketRef2.current.close();
                socketRef2.current = null;
                console.log("notificationSocket connection closed");
            }
        } else {
            if (socketRef.current) {
                console.log("globalSocket is still connected");
            } else {
                if (relatedUserProfileId && relatedUserProfileId.length > 0) {
                    console.log("Connecting to globalSocket");
                    globalSocketCloseHandler(relatedUserProfileId)
                    setupNotifications();
                } else {
                    console.log("Connecting to globalSocket");
                    globalSocketCloseHandler(profileId);
                    setupNotifications();
                }
            }
        }

    }, [isConnected]);

    // Global message handler
    // Modified Global message handler
    const globalMessageHandler = useCallback(async (event) => {
        console.log("Message received:", event.data);
        const message = JSON.parse(event.data);
    
        if (notAllowTaskTypeNotification.includes(message.message_type)) {
            console.log(`Skipping notification for message type: ${message.message_type}`);
            return;
        }
    
        let currentSubscription = pushSubscription;
        if (!currentSubscription) {
            const storedSubscription = localStorage.getItem('pushSubscription');
            if (storedSubscription) {
                currentSubscription = JSON.parse(storedSubscription);
            }
        }
    
        console.log("Current push subscription:", currentSubscription);
        console.log("Notification permission:", Notification.permission);
    
        if (Notification.permission === 'granted' && currentSubscription) {
            try {
                await API.sendPushNotification(currentSubscription, {
                    title: message.from_todo_message 
                        ? `Todo-list added from ${message.message_sender}`
                        : `💬 New Message from ${message.message_sender}`,
                    body: message.message
                });
                console.log("Push notification sent successfully");
            } catch (error) {
                console.error('Error sending push notification:', error);
            }
        } else {
            console.log('Notification permission not granted or push subscription not available');
        }
    }, [pushSubscription, notAllowTaskTypeNotification]);

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }


    // Global socket close handler
    const globalSocketCloseHandler = (profileId) => {

        if (profileId && !socketRef.current) {
            socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/notifications/${profileId}/`);
            
            socketRef.current.onopen = () => {
                console.log("[globalSocket] Connection established");
            };
            // Set the global message handler as the default
            // socketRef.current.onmessage = globalMessageHandler;
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                console.log("WebSocket closed");
                socketRef.current = null;
            }
        };
    };

    
    const toggleConnection = (enable) => {
        setIsConnected(enable);
    };

    const toggleNotification = (enable) => {
        setNotificationToggle(enable);
    };


    const updateActivePhoneNumber = (phoneNumber) => {
        setActivePhoneNumber(phoneNumber);
    };

    return (
        <WebSocketContext.Provider value={{ 
            socketRef, 
            socketRef2, 
            updateActivePhoneNumber, 
            toggleConnection, 
            toggleNotification,
            notificationPermission,
            pushSubscription
        }}>
            {children}
        </WebSocketContext.Provider>
    );
};