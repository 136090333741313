import React, { useEffect, useState } from 'react';
import PostAuthor from './PostAuthor';
import './css/PostDetail.css';
import { MainHeader, MainFooter } from "../components/Layout";

const PostDetail2 = () => {
  const [tableOfContents, setTableOfContents] = useState([]);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const headers = document.querySelectorAll('.post-detail_container h1, .post-detail_container h2');
    const toc = [];
    let currentH1 = null;

    headers.forEach((header, index) => {
      const id = `section-${index}`;
      header.id = id;
      const item = { 
        id, 
        title: header.textContent, 
        level: parseInt(header.tagName[1])
      };

      if (item.level === 1) {
        currentH1 = item;
        toc.push(item);
      } else if (item.level === 2) {
        if (currentH1) {
          if (!currentH1.children) currentH1.children = [];
          currentH1.children.push(item);
        } else {
          toc.push(item);
        }
      }
    });

    setTableOfContents(toc);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', updateActiveSection);
    return () => window.removeEventListener('scroll', updateActiveSection);
  }, []);

  const updateActiveSection = () => {
    const scrollPosition = window.scrollY;
    const headers = document.querySelectorAll('.post-detail_container h1, .post-detail_container h2');
    
    for (let i = headers.length - 1; i >= 0; i--) {
      const header = headers[i];
      if (header.offsetTop <= scrollPosition + 150) {
        setActiveSection(header.id);
        break;
      }
    }
  };

  const scrollToSection = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -130; 
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  };

  const renderTocItem = (item) => (
    <li key={item.id} className={activeSection === item.id ? 'active' : ''}>
      <a 
        href={`#${item.id}`} 
        onClick={(e) => scrollToSection(e, item.id)}
      >
        {item.title}
      </a>
      {item.children && (
        <ul style={{ marginLeft: '20px' }}>
          {item.children.map(child => (
            <li key={child.id} className={activeSection === child.id ? 'active' : ''}>
              <a 
                href={`#${child.id}`} 
                onClick={(e) => scrollToSection(e, child.id)}
              >
                {child.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );

  return (
    <section className='post-detail-main'>
      <MainHeader/>
      <div className="post-detail-wrapper">
        <aside className="table-of-contents">
          <h2>TABLE OF CONTENTS</h2>
          <ul>
            {tableOfContents.map(item => renderTocItem(item))}
          </ul>
        </aside>
            <div className="post-detail">
            <div className="container post-detail_container">
                <h3>Common Errors in the Embedded WhatsApp Signup Flow: A Troubleshooting Guide</h3>
                <div className="post-detail_header">
                    <PostAuthor/>
                </div>
                <div className="post-detail_thumbnail">
                  <img src={require("../assets/blog2-pic.png")} alt=""></img>
                </div>
                <p>As businesses increasingly adopt the WhatsApp Business API to streamline customer communication, many encounter challenges during the onboarding process, particularly within the embedded signup flow. Whether you're trying to set up a Business Manager account or configure a phone number for WhatsApp, it’s important to be aware of common errors and how to address them.</p>
                <p>
                This guide covers some of the most frequent issues faced during the WhatsApp Business API setup process, providing insights on how to resolve them and ensure a smooth integration.
                </p>
                <h1>Business Manager Account Creation Errors</h1>
                <p>The Business Manager account is central to the WhatsApp Business API setup. However, creating or managing a Business Manager account often presents several challenges. Whether it’s a vague error message or an account creation limit, these errors can prevent businesses from completing their onboarding. This section outlines common Business Manager account creation errors and offers solutions to keep the process moving smoothly.</p>
                <div className="post-detail_img">
                  <img src={require("../assets/blog2-pic2.jpg")} alt=""></img>
                </div>
                <p3>"An error occurred while processing this request. Please try again later."</p3>
                <p>This vague message can leave users confused. It usually stems from issues during the Business Manager account creation.</p>
                <p3>Solution:</p3>
                <p>Ensure you're using an active Facebook account. If the issue persists, reach out to Meta’s support team for assistance.</p>
                <hr></hr>
                <p3>"You have reached the limit for the number of Businesses you can create at this time."</p3>
                <p>There are restrictions on how many Business Accounts can be created under one Facebook profile.</p>
                <p3>Solution:</p3>
                <p>Use an existing Business Account or close any unused accounts before attempting to create a new one.</p>
                <hr></hr>
                <p3>"Your Facebook account is too new to create a business account. Try again in an hour."</p3>
                <p>New Facebook accounts may face delays when attempting to create Business Manager accounts.</p>
                <p3>Solution:</p3>
                <p>Use an established Facebook account, or wait a few hours before retrying. Engage actively with the new account in the meantime to expedite the process.</p>
                <hr></hr>
                <p3>"We limit how often you can post, comment, or perform other actions to help protect the community from spam. You can try again later."</p3>
                <p>This indicates that your Facebook account has been flagged due to unusual activity.</p>
                <p3>Solution:</p3>
                <p>Switch to an active Facebook account that has no prior issues or restrictions.</p>
                <hr></hr>
                <p3>"You're no longer allowed to use Facebook Products to advertise. You can't run ads, manage advertising assets, or create new ad or business accounts."</p3>
                <p>This error usually occurs when Meta has flagged your account for suspicious activity.</p>
                <p3>Solution:</p3>
                <p>You’ll need to use a different, unrestricted Facebook account to proceed.</p>
                <h1>WhatsApp Business Account Creation Errors</h1>
                <p>Once the Business Manager account is created, the next step is setting up a WhatsApp Business account. This process can also present its own set of challenges, particularly regarding permissions and verification requirements. In this section, we’ll review common errors that arise during the WhatsApp Business account creation and how to solve them.</p>
                <div className="post-detail_img">
                  <img src={require("../assets/blog2-pic3.jpeg")} alt=""></img>
                </div>
                <p3>"User does not have permission to create WhatsApp Business Accounts."</p3>
                <p>This typically occurs when the user lacks the necessary admin permissions to create a WhatsApp Business Account.</p>
                <p3>Solution:</p3>
                <p>Obtain admin access to the selected Business Account or choose one for which you already have admin permissions.</p>
                <hr></hr>
                <p3>
                "You can only create a limited number of WhatsApp Business Accounts before your business and WhatsApp account verification is complete."
                </p3>
                <p>
                Businesses can create a limited number of WhatsApp accounts until the verification process is fully completed.
                </p>
                <p3>
                    Solution:
                </p3>
                <p>
                Complete your business verification in Facebook Business Manager, or use a previously verified account to proceed.
                </p>
                <hr></hr>
                <p3>"We can't verify the Facebook Business Account that you selected. Go back to the previous screen or contact support."</p3>
                <p>This error signifies that the selected Facebook Business Account doesn't comply with Meta’s policies for WhatsApp Business API.</p>
                <p3>Solution:</p3>
                <p>Check the Business Manager account for further details. If your verification was rejected, you should have received an email outlining the reasons. You can reapply if you believe the rejection was an error.</p>
                <h1>Phone Setup Errors</h1>
                <img src={require("../assets/blog2-pic5.jpeg")} alt=""></img>
                <p>After successfully creating the WhatsApp Business account, the next step involves setting up a phone number. This step is crucial, as the phone number is the identifier for your business within WhatsApp. Errors during this process often involve phone number conflicts or verification issues. Here, we’ll explore common phone setup errors and how to resolve them.</p>
                <p3>
                "This phone number already exists in your list of phone numbers." 
                </p3>
                <p>
                This happens when you attempt to add a phone number that’s already associated with your WhatsApp Business Account.
                </p>
                <p3>
                    Solution:
                </p3>
                <p>
                Select the existing phone number from your account or start the flow over to resolve this error.
                </p>
                <hr></hr>
                <p3>
                "This number is registered to an existing WhatsApp account. To use this number, disconnect it from the existing account."
                </p3>
                <p>
                Your phone number is already linked to a WhatsApp Messenger or Business App account.
                </p>
                <p3>Solution:</p3>
                <p>To use this number with the WhatsApp Business API, deregister it from the current platform or select a different number.</p>
                <hr></hr>
                <p3>"Your verified name violates WhatsApp guidelines. Please edit your verified name and try again."</p3>
                <p>
                The business name associated with your account doesn't comply with WhatsApp’s naming guidelines.
                </p>
                <p3>Solution:</p3>
                <p>
                Update the verified name in accordance with the Display Name guidelines and re-enter it.  
                </p>
                <h1>Phone Verification Errors</h1>
                <p>Once your phone number is set up, the final hurdle is verifying the number. This step confirms your ownership of the number and authorizes its use within the WhatsApp API. Several issues can arise during phone verification, especially if the phone number is invalid or if there have been too many verification attempts. In this section, we’ll cover the common verification errors and how to overcome them.</p>
                <img src={require("../assets/blog2-pic4.png")} alt=""></img>
                <p3>"Phone number ownership is already verified."</p3>
                <p>If you see this message, your phone number has already been verified.</p>
                <p3>Solution:</p3>
                <p>Refresh the page to reflect the change, or proceed with the next steps in the setup.</p>
                <hr></hr>
                <p3>"Your phone number doesn't appear to be valid."</p3>
                <p>This error suggests that the phone number entered is either incorrect or unsupported.</p>
                <p3>Solution:</p3>
                <p>Double-check the phone number and ensure it is operational. Avoid using IVR numbers, which WhatsApp does not support.</p>
                <hr></hr>
                <p3>"You have guessed too many times."</p3>
                <p>To prevent spam, Meta limits the number of verification attempts for phone numbers.</p>
                <p3>Solution:</p3>
                <p>Wait approximately 12 hours before trying again, and make sure to select the correct phone number during the next attempt.</p>
                <hr></hr>
                <p3>"You have requested your code too many times."</p3>
                <p>Similarly, this error indicates that the number of code requests has been exceeded within a specified time frame.</p>
                <p3>Solution:</p3>
                <p>Wait for the cooldown period to end, then try requesting the verification code again.</p>
                <h1>Conclusion</h1>
                <p>Understanding these common errors and their solutions is essential for a smooth WhatsApp Business API setup. Whether you're dealing with Business Manager issues, phone setup problems, or verification errors, having the right information at hand can save time and prevent frustration.</p>
                <p>By addressing these issues promptly, you’ll be well on your way to leveraging the WhatsApp Business API’s powerful tools to grow and streamline your business.</p>
            </div>
            </div>
            </div>
        </section>
    )
}

export default PostDetail2;