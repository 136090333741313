import React from 'react';
import { Link } from 'react-router-dom';
import PostAuthor from './PostAuthor';

const PostItem = ({ postID, category, title, description, authorID, thumbnail, postLink }) => {
    const shortDescription = description.length > 145 ? description.substr(0, 100) + '...' : description;
    const postTitle = title.length > 100 ? title.substr(0, 100) + '...' : title;

    return (
        <article className="post">
            <div className="post_thumbnail">
                <img src={thumbnail} alt={title} />
            </div>
            <div className="post_content">
                {/* Use the dynamic postLink prop to determine the link */}
                <Link to={postLink}>
                    <h3>{postTitle}</h3>
                </Link>
                <p>{shortDescription}</p>
                <div className="post_footer">
                    <PostAuthor />
                    {/* Category link */}
                    {/* <Link className="category_item" to={`/CategoryPosts/${category}`}>{category}</Link> */}
                </div>
            </div>
        </article>
    );
}

export default PostItem;
